import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form-field-autocomplete-container" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "dropdown-menu" }
const _hoisted_6 = {
  key: 0,
  class: "dropdown-divider"
}
const _hoisted_7 = ["onClick", "onKeyup"]
const _hoisted_8 = ["id"]

import { useCountryInput } from '@src/components/shared/form_fields/useCountryInput';
import { useFilteredCountries } from '@src/components/shared/form_fields/useFilteredCountries';
import { Country } from '@src/view_models/Country';
import TextFormInput from '@src/components/shared/form_elements/TextFormInput.vue';
import { computed, nextTick, ref } from 'vue';
import { updateAutocompleteScrollPosition } from '@src/components/shared/form_fields/updateAutocompleteScrollPosition';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';
import { autoscrollMaxWidth, useAutocompleteScrollIntoViewOnFocus } from '@src/components/shared/form_fields/useAutocompleteScrollIntoViewOnFocus';

enum InteractionState {
	Typing,
	Selecting,
}

interface Props {
	modelValue: string;
	inputId: string;
	scrollTargetId: string;
	label: String;
	placeholder: String;
	countries?: Array<Country>;
	showError: boolean;
	errorMessage: String;
	wasRestored: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CountryAutocompleteField',
  props: {
    modelValue: {},
    inputId: {},
    scrollTargetId: {},
    label: {},
    placeholder: {},
    countries: {},
    showError: { type: Boolean },
    errorMessage: {},
    wasRestored: { type: Boolean }
  },
  emits: [ 'initialised', 'field-changed', 'update:modelValue' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const { country, countryName } = useCountryInput( props.modelValue, props.countries, emit );
const { filteredCountries, groupSeparatorIndex } = useFilteredCountries( props.countries, countryName );
const interactionState = ref<InteractionState>( InteractionState.Typing );
const activeCountry = ref<string>();
const activeCountryName = computed<string>( () => {
	return filteredCountries.value.find( x => x.countryCode === activeCountry.value )?.countryFullName;
} );
const scrollElement = ref<HTMLElement>();
const wasFocusedBefore = ref<Boolean>( false );
const autocompleteIsActive = ref<Boolean>( false );
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => activeCountry.value ? `${props.inputId}-selected` : '' ),
	`${props.inputId}-error`,
	computed<boolean>( () => props.showError )
);
const scrollIntoView = useAutocompleteScrollIntoViewOnFocus( props.scrollTargetId, autoscrollMaxWidth );

const isFirstFocusOnDefaultValue = (): boolean => {
	return !wasFocusedBefore.value && !props.wasRestored;
};

const onFocus = ( event: Event ) => {
	if ( isFirstFocusOnDefaultValue() ) {
		countryName.value = '';
	}
	wasFocusedBefore.value = true;

	autocompleteIsActive.value = true;
	scrollIntoView();
	( event.target as HTMLInputElement ).select();
};

const onKeydown = ( event: KeyboardEvent ) => {
	if ( [ 'ArrowUp', 'ArrowDown', 'Tab', 'Enter' ].includes( event.key ) ) {
		return true;
	}

	interactionState.value = InteractionState.Typing;
	activeCountry.value = undefined;
};

const onKeyArrows = async ( direction: 'up' | 'down' ) => {
	interactionState.value = InteractionState.Selecting;

	if ( activeCountry.value === undefined ) {
		activeCountry.value = filteredCountries.value[ 0 ].countryCode;
		return;
	}

	let index = filteredCountries.value.findIndex( x => x.countryCode === activeCountry.value );

	if ( direction === 'up' && index > 0 ) {
		index--;
	}

	if ( direction === 'down' && index + 1 < filteredCountries.value.length ) {
		index++;
	}

	activeCountry.value = filteredCountries.value[ index ].countryCode;

	await nextTick();
	updateAutocompleteScrollPosition( scrollElement );
};

const onKeySubmit = () => {
	if ( interactionState.value === InteractionState.Typing ) {
		return;
	}

	countryName.value = filteredCountries.value.find( x => x.countryCode === activeCountry.value )?.countryFullName;
};

let itemWasJustSelectedFromList = false;

const onBlur = ( selectedCountry: Country ) => {
	setTimeout( () => {
		autocompleteIsActive.value = false;

		if ( !itemWasJustSelectedFromList ) {
			emit( 'field-changed', selectedCountry ?? '' );
		}
		itemWasJustSelectedFromList = false;
	}, 200 );
};

const onSelectItem = async ( selectedCountry: Country ) => {
	itemWasJustSelectedFromList = true;
	countryName.value = selectedCountry.countryFullName;
	await nextTick();
	emit( 'field-changed', selectedCountry );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field form-field-autocomplete", { 'is-invalid': _ctx.showError }])
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: "form-field-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TextFormInput, {
        modelValue: _unref(countryName),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(countryName) ? (countryName).value = $event : null)),
        "input-type": "text",
        placeholder: _ctx.placeholder,
        "has-error": _ctx.showError,
        "has-message": false,
        "input-id": _ctx.inputId,
        name: "countrySelector",
        onFocus: onFocus,
        onBlur: _cache[1] || (_cache[1] = () => onBlur( _unref(country) )),
        onKeydown: [
          onKeydown,
          _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (onKeyArrows('up')), ["prevent"]), ["up"])),
          _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (onKeyArrows('down')), ["prevent"]), ["down"])),
          _withKeys(onKeySubmit, ["tab"]),
          _withKeys(onKeySubmit, ["enter"])
        ],
        "aria-describedby": _unref(ariaDescribedby),
        "aria-autocomplete": "list"
      }, null, 8, ["modelValue", "placeholder", "has-error", "input-id", "aria-describedby"]),
      _createElementVNode("span", {
        class: "is-sr-only",
        id: `${_ctx.inputId}-selected`,
        "aria-live": "assertive"
      }, _toDisplayString(activeCountryName.value), 9, _hoisted_3),
      _createElementVNode("input", {
        type: "hidden",
        name: "country",
        value: _unref(country)?.countryCode
      }, null, 8, _hoisted_4),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "dropdown-content",
              ref_key: "scrollElement",
              ref: scrollElement,
              tabindex: "-1"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredCountries), (country, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (_unref(groupSeparatorIndex) === index)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                    : _createCommentVNode("", true),
                  _createElementVNode("a", {
                    class: _normalizeClass(["dropdown-item", { 'is-active-item': country.countryCode === activeCountry.value }]),
                    role: "button",
                    tabindex: "-1",
                    onClick: _withModifiers(() => onSelectItem( country ), ["stop"]),
                    onKeyup: _withKeys(($event: any) => (onSelectItem(country)), ["enter","space"])
                  }, _toDisplayString(country.countryFullName), 43, _hoisted_7)
                ], 64))
              }), 128))
            ], 512)
          ], 512), [
            [_vShow, autocompleteIsActive.value]
          ])
        ]),
        _: 1
      })
    ]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "help is-danger",
          id: `${_ctx.inputId}-error`
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_8))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "message")
  ], 2))
}
}

})