import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "address-section" }

import RadioField from '@src/components/shared/form_fields/RadioField.vue';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { useStore } from 'vuex';
import { useAddressTypeModel } from '@src/components/pages/donation_form/DonationReceipt/useAddressTypeModel';
import { AddressFormData, AddressValidity } from '@src/view_models/Address';
import TextField from '@src/components/shared/form_fields/TextField.vue';
import ValueEqualsPlaceholderWarning from '@src/components/shared/ValueEqualsPlaceholderWarning.vue';
import { computed, onBeforeMount, ref } from 'vue';
import CityAutocompleteField from '@src/components/shared/form_fields/CityAutocompleteField.vue';
import CountryAutocompleteField from '@src/components/shared/form_fields/CountryAutocompleteField.vue';
import StreetAutocompleteField from '@src/components/shared/form_fields/StreetAutocompleteField.vue';
import { Country } from '@src/view_models/Country';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import { Validity } from '@src/view_models/Validity';

interface Props {
	formData: AddressFormData;
	showError: AddressValidity;
	countries: Country[];
	postCodeValidation: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressFields',
  props: {
    formData: {},
    showError: {},
    countries: {},
    postCodeValidation: {}
  },
  emits: [ 'field-changed' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const store = useStore();
const addressType = useAddressTypeModel( store );

const showAddressTypeError = computed( () => store.getters[ 'address/addressTypeIsInvalid' ] );
const countryWasRestored = ref<boolean>( false );

const onCountryFieldChanged = ( country: Country | undefined ) => {
	if ( country ) {
		props.formData.postcode.pattern = country.postCodeValidation;
	} else {
		props.formData.postcode.pattern = props.postCodeValidation;
	}

	emit( 'field-changed', 'country' );

	if ( props.formData.postcode.value !== '' ) {
		emit( 'field-changed', 'postcode' );
	}
};

onBeforeMount( () => {
	countryWasRestored.value = store.state.address.validity.country === Validity.RESTORED;
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ScrollTarget, { "target-id": "address-type-scroll-target" }),
    _createVNode(RadioField, {
      modelValue: _unref(addressType),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(addressType) ? (addressType).value = $event : null)),
      name: "addressTypeSelector",
      options: [
				{ value: _unref(AddressTypeModel).PERSON, label: _ctx.$t( 'C24_WMDE_Desktop_DE_01_contact_details_private' ), id: 'addressType-0' },
				{ value: _unref(AddressTypeModel).COMPANY_WITH_CONTACT, label: _ctx.$t( 'C24_WMDE_Desktop_DE_01_contact_details_company' ), id: 'addressType-1' },
			],
      label: _ctx.$t( 'C24_WMDE_Desktop_DE_01_contact_details_label' ),
      "show-error": showAddressTypeError.value,
      "error-message": _ctx.$t( 'donation_form_section_address_error' ),
      alignment: "row"
    }, null, 8, ["modelValue", "options", "label", "show-error", "error-message"]),
    _createVNode(ScrollTarget, { "target-id": "company-name-scroll-target" }),
    (_unref(addressType) === _unref(AddressTypeModel).COMPANY_WITH_CONTACT)
      ? (_openBlock(), _createBlock(TextField, {
          key: 0,
          name: "companyName",
          "input-id": "company-name",
          modelValue: _ctx.formData.companyName.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.companyName.value) = $event)),
          "show-error": _ctx.showError.companyName,
          "error-message": _ctx.$t( 'donation_form_companyname_error' ),
          autocomplete: "organization",
          label: _ctx.$t( 'donation_form_companyname_label' ),
          placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_companyname_placeholder' ) } ),
          onFieldChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('field-changed', 'companyName')))
        }, null, 8, ["modelValue", "show-error", "error-message", "label", "placeholder"]))
      : _createCommentVNode("", true),
    _createVNode(ScrollTarget, { "target-id": "country-scroll-target" }),
    _createVNode(CountryAutocompleteField, {
      modelValue: _ctx.formData.country.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.country.value) = $event)),
      "input-id": "country",
      "scroll-target-id": "country-scroll-target",
      countries: _ctx.countries,
      "was-restored": countryWasRestored.value,
      "show-error": _ctx.showError.country,
      "error-message": _ctx.$t('donation_form_country_error'),
      label: _ctx.$t( 'donation_form_country_label' ),
      placeholder: _ctx.$t( 'form_for_example', { example: _ctx.countries[0].countryFullName } ),
      onFieldChanged: onCountryFieldChanged
    }, null, 8, ["modelValue", "countries", "was-restored", "show-error", "error-message", "label", "placeholder"]),
    _createVNode(ScrollTarget, { "target-id": "post-code-scroll-target" }),
    _createVNode(TextField, {
      name: "postcode",
      "input-id": "post-code",
      modelValue: _ctx.formData.postcode.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.postcode.value) = $event)),
      "show-error": _ctx.showError.postcode,
      "error-message": _ctx.$t('donation_form_zip_error'),
      autocomplete: "postal-code",
      label: _ctx.$t( 'donation_form_zip_label' ),
      placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_zip_placeholder' ) } ),
      onFieldChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('field-changed', 'postcode')))
    }, {
      default: _withCtx(() => [
        _createVNode(ValueEqualsPlaceholderWarning, {
          value: _ctx.formData.postcode.value,
          placeholder: _ctx.$t( 'donation_form_zip_placeholder' ),
          warning: 'donation_form_zip_placeholder_warning'
        }, null, 8, ["value", "placeholder"])
      ]),
      _: 1
    }, 8, ["modelValue", "show-error", "error-message", "label", "placeholder"]),
    _createVNode(ScrollTarget, { "target-id": "city-scroll-target" }),
    _createVNode(CityAutocompleteField, {
      modelValue: _ctx.formData.city.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.city.value) = $event)),
      "input-id": "city",
      "scroll-target-id": "city-scroll-target",
      "show-error": _ctx.showError.city,
      label: _ctx.$t( 'donation_form_city_label' ),
      "error-message": _ctx.$t( 'donation_form_city_error' ),
      postcode: _ctx.formData.postcode.value,
      "example-placeholder": "donation_form_city_placeholder",
      onFieldChanged: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('field-changed', 'city' )))
    }, {
      default: _withCtx(() => [
        _createVNode(ValueEqualsPlaceholderWarning, {
          value: _ctx.formData.city.value,
          placeholder: _ctx.$t( 'donation_form_city_placeholder' ),
          warning: "donation_form_city_placeholder_warning"
        }, null, 8, ["value", "placeholder"])
      ]),
      _: 1
    }, 8, ["modelValue", "show-error", "label", "error-message", "postcode"]),
    _createVNode(ScrollTarget, { "target-id": "street-scroll-target" }),
    _createVNode(StreetAutocompleteField, {
      "input-id-street-name": "street",
      "input-id-building-number": "building-number",
      "scroll-target-id": "street-scroll-target",
      modelValue: _ctx.formData.street.value,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.street.value) = $event)),
      postcode: _ctx.formData.postcode.value,
      "show-error": _ctx.showError.street,
      "error-message": _ctx.$t( 'donation_form_street_error' ),
      onFieldChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('field-changed', 'street' )))
    }, null, 8, ["modelValue", "postcode", "show-error", "error-message"])
  ]))
}
}

})