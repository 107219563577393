import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "laika-donation" }
const _hoisted_2 = {
  key: 0,
  class: "donation-page-form-section"
}
const _hoisted_3 = { class: "donation-page-form-section" }
const _hoisted_4 = ["action"]

import { inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import { TrackingData } from '@src/view_models/TrackingData';
import { Country } from '@src/view_models/Country';
import { AddressValidation } from '@src/view_models/Validation';
import { Salutation } from '@src/view_models/Salutation';
import { CampaignValues } from '@src/view_models/CampaignValues';
import PaymentSection from '@src/components/pages/donation_form/FormSections/PaymentSection.vue';
import PersonalDataSection from '@src/components/pages/donation_form/FormSections/PersonalDataSection.vue';
import IbanFields from '@src/components/shared/IbanFields.vue';
import DonationSummary from '@src/components/pages/donation_form/DonationSummary.vue';
import PaymentTextFormButton from '@src/components/shared/form_elements/PaymentTextFormButton.vue';
import FormButton from '@src/components/shared/form_elements/FormButton.vue';
import FormSummary from '@src/components/shared/FormSummary.vue';
import SubmitValues from '@src/components/pages/donation_form/SubmitValues.vue';
import ErrorSummary from '@src/components/pages/donation_form/ErrorSummary.vue';
import { useDonationFormSubmitHandler } from '@src/components/pages/donation_form/useDonationFormSubmitHandler';
import { QUERY_STRING_INJECTION_KEY } from '@src/util/createCampaignQueryString';
import { usePaymentFunctions } from '@src/components/pages/donation_form/usePaymentFunctions';
import { useAddressSummary } from '@src/components/pages/donation_form/useAddressSummary';
import { useAddressTypeFunctions } from '@src/components/shared/composables/useAddressTypeFunctions';
import { trackDynamicForm } from '@src/util/tracking';

interface Props {
	assetsPath: string;
	validateAddressUrl: string;
	validateEmailUrl: string;
	validateBankDataUrl: string;
	validateLegacyBankDataUrl: string;
	paymentAmounts: number[];
	paymentIntervals: number[];
	paymentTypes: string[];
	countries: Country[];
	salutations: Salutation[];
	trackingData: TrackingData;
	campaignValues: CampaignValues;
	addressValidationPatterns: AddressValidation;
}

export default /*@__PURE__*/_defineComponent({
  ...{
	name: 'DonationForm',
},
  __name: 'DonationForm',
  props: {
    assetsPath: {},
    validateAddressUrl: {},
    validateEmailUrl: {},
    validateBankDataUrl: {},
    validateLegacyBankDataUrl: {},
    paymentAmounts: {},
    paymentIntervals: {},
    paymentTypes: {},
    countries: {},
    salutations: {},
    trackingData: {},
    campaignValues: {},
    addressValidationPatterns: {}
  },
  setup(__props: any) {



const props = __props;

const store = useStore();
const { isDirectDebitPayment, paymentSummary } = usePaymentFunctions( store );
const { addressSummary, inlineSummaryLanguageItem } = useAddressSummary( store );
const {
	disabledAddressTypes,
	addressType,
	addressTypeIsInvalid,
	addressTypeName,
	setAddressType,
} = useAddressTypeFunctions( store );

const campaignParams = inject<string>( QUERY_STRING_INJECTION_KEY, '' );

const { submit, submitValuesForm, showErrorSummary } = useDonationFormSubmitHandler(
	store,
	addressType,
	isDirectDebitPayment,
	props.validateAddressUrl,
	props.validateEmailUrl
);

const scrollToPaymentSection = () => {
	const scrollIntoViewElement = document.getElementById( 'payment-section-top-scroll-target' );
	if ( scrollIntoViewElement ) {
		scrollIntoViewElement.scrollIntoView( { behavior: 'smooth' } );
	}
};

onMounted( () => {
	trackDynamicForm();
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PaymentSection, {
      "payment-amounts": _ctx.paymentAmounts,
      "payment-intervals": _ctx.paymentIntervals,
      "payment-types": _ctx.paymentTypes
    }, null, 8, ["payment-amounts", "payment-intervals", "payment-types"]),
    (_unref(isDirectDebitPayment))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(IbanFields)
        ]))
      : _createCommentVNode("", true),
    _createVNode(PersonalDataSection, {
      countries: _ctx.countries,
      salutations: _ctx.salutations,
      "tracking-data": _ctx.trackingData,
      "campaign-values": _ctx.campaignValues,
      "address-validation-patterns": _ctx.addressValidationPatterns,
      "is-direct-debit-payment": _unref(isDirectDebitPayment),
      "disabled-address-types": _unref(disabledAddressTypes),
      "address-type": _unref(addressType),
      "address-type-is-invalid": _unref(addressTypeIsInvalid),
      onSetAddressType: _cache[0] || (_cache[0] = ($event: any) => (_unref(setAddressType)( $event )))
    }, null, 8, ["countries", "salutations", "tracking-data", "campaign-values", "address-validation-patterns", "is-direct-debit-payment", "disabled-address-types", "address-type", "address-type-is-invalid"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(ErrorSummary, {
        "show-error-summary": _unref(showErrorSummary),
        "address-type": _unref(addressType)
      }, null, 8, ["show-error-summary", "address-type"]),
      _createVNode(FormSummary, null, {
        "summary-content": _withCtx(() => [
          _createVNode(DonationSummary, {
            payment: _unref(paymentSummary),
            "address-type": _unref(addressTypeName),
            address: _unref(addressSummary),
            countries: _ctx.countries,
            salutations: _ctx.salutations,
            "language-item": _unref(inlineSummaryLanguageItem)
          }, null, 8, ["payment", "address-type", "address", "countries", "salutations", "language-item"])
        ]),
        "summary-buttons": _withCtx(() => [
          _createVNode(FormButton, {
            id: "previous-btn",
            "is-outlined": true,
            onClick: scrollToPaymentSection
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_section_back' )), 1)
            ]),
            _: 1
          }),
          _createVNode(PaymentTextFormButton, {
            id: "submit-btn",
            "is-loading": _unref(store).getters.isValidating,
            "payment-type": _unref(paymentSummary).paymentType,
            onClick: _unref(submit)
          }, null, 8, ["is-loading", "payment-type", "onClick"])
        ]),
        _: 1
      }),
      _createElementVNode("form", {
        action: `/donation/add?${_unref(campaignParams)}`,
        method: "post",
        ref_key: "submitValuesForm",
        ref: submitValuesForm,
        id: "submit-form"
      }, [
        _createVNode(SubmitValues, {
          "tracking-data": _ctx.trackingData,
          "campaign-values": _ctx.campaignValues
        }, null, 8, ["tracking-data", "campaign-values"])
      ], 8, _hoisted_4)
    ])
  ]))
}
}

})