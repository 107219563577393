import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form-field-autocomplete-container" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "dropdown-menu" }
const _hoisted_5 = ["onClick", "onKeyup"]
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 0,
  class: "street-number-warning help"
}

import TextField from '@src/components/shared/form_fields/TextField.vue';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { joinStreetAndBuildingNumber, splitStreetAndBuildingNumber } from '@src/util/street_and_building_number_tools';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';
import { NullStreetAutocompleteResource, StreetAutocompleteResource } from '@src/api/StreetAutocompleteResource';
import { useStreetsResource } from '@src/components/shared/form_fields/useStreetsResource';
import TextFormInput from '@src/components/shared/form_elements/TextFormInput.vue';
import { updateAutocompleteScrollPosition } from '@src/components/shared/form_fields/updateAutocompleteScrollPosition';
import ValueEqualsPlaceholderWarning from '@src/components/shared/ValueEqualsPlaceholderWarning.vue';
import { autoscrollMaxWidth, useAutocompleteScrollIntoViewOnFocus } from '@src/components/shared/form_fields/useAutocompleteScrollIntoViewOnFocus';

enum InteractionState {
	Typing,
	Selecting,
}

interface Props {
	inputIdStreetName: string;
	inputIdBuildingNumber: string;
	scrollTargetId: string;
	modelValue: string;
	showError: boolean;
	errorMessage: String;
	postcode: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StreetAutocompleteField',
  props: {
    inputIdStreetName: {},
    inputIdBuildingNumber: {},
    scrollTargetId: {},
    modelValue: {},
    showError: { type: Boolean },
    errorMessage: {},
    postcode: {}
  },
  emits: [ 'update:modelValue', 'field-changed' ],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const streetNameModel = ref<string>( '' );
const buildingNumberModel = ref<string>( '' );
const buildingNumberWasBlurred = ref<boolean>( false );
const showBuildingNumberWarning = computed( () => buildingNumberWasBlurred.value && buildingNumberModel.value === '' );
const autocompleteIsActive = ref<Boolean>( false );
const { streets, fetchStreetsForPostcode } = useStreetsResource( inject<StreetAutocompleteResource>( 'streetAutocompleteResource', NullStreetAutocompleteResource ) );
const activeStreet = ref<string>();
const interactionState = ref<InteractionState>( InteractionState.Typing );
const scrollElement = ref<HTMLElement>();
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => activeStreet.value ? `${props.inputIdStreetName}-selected` : '' ),
	`${props.inputIdStreetName}-error`,
	computed<boolean>( () => props.showError )
);
const scrollIntoView = useAutocompleteScrollIntoViewOnFocus( props.scrollTargetId, autoscrollMaxWidth );

const filteredStreets = computed<Array<string>>( () => {
	const streetList = streets.value.filter( ( streetItem: string ) => {
		return streetItem
			.toLowerCase()
			.startsWith( streetNameModel.value.trim().toLowerCase() );
	} );

	return streetNameModel.value !== '' || streetList.length > 0 ? streetList : streets.value;
} );

const onUpdateModel = (): void => {
	emit( 'update:modelValue', joinStreetAndBuildingNumber( streetNameModel.value, buildingNumberModel.value ) );
};

const onStreetNameFocus = ( event: Event ) => {
	autocompleteIsActive.value = true;
	scrollIntoView();
	( event.target as HTMLInputElement ).select();
};

const onStreetNameKeydown = ( event: KeyboardEvent ) => {
	if ( [ 'ArrowUp', 'ArrowDown', 'Tab', 'Enter' ].includes( event.key ) ) {
		return true;
	}

	interactionState.value = InteractionState.Typing;
	activeStreet.value = undefined;
	updateAutocompleteScrollPosition( scrollElement );
};

const onStreetNameKeyArrows = async ( direction: 'up' | 'down' ) => {
	interactionState.value = InteractionState.Selecting;

	if ( activeStreet.value === undefined ) {
		activeStreet.value = filteredStreets.value[ 0 ];
		return;
	}

	let index = filteredStreets.value.findIndex( x => x === activeStreet.value );

	if ( direction === 'up' && index > 0 ) {
		index--;
	}

	if ( direction === 'down' && index + 1 < filteredStreets.value.length ) {
		index++;
	}

	activeStreet.value = filteredStreets.value[ index ];

	await nextTick();
	updateAutocompleteScrollPosition( scrollElement );
};

const onStreetNameKeySubmit = () => {
	if ( interactionState.value === InteractionState.Typing ) {
		return;
	}

	streetNameModel.value = activeStreet.value;
};

let itemWasJustSelectedFromList = false;

const onStreetNameBlur = () => {
	setTimeout( () => {
		autocompleteIsActive.value = false;
		if ( !itemWasJustSelectedFromList ) {
			emit( 'field-changed' );
		}
		itemWasJustSelectedFromList = false;
	}, 200 );
};

const onSelectStreet = async ( newStreet: string ) => {
	itemWasJustSelectedFromList = true;
	streetNameModel.value = newStreet;
	await nextTick();
	emit( 'field-changed' );
};

const setModelValues = ( newValue: string ) => {
	const values = splitStreetAndBuildingNumber( newValue );
	streetNameModel.value = values.street;
	buildingNumberModel.value = values.buildingNumber;
};

onMounted( () => {
	setModelValues( props.modelValue );
	fetchStreetsForPostcode( props.postcode );
} );
watch( () => props.modelValue, ( newValue: string ) => setModelValues( newValue ) );
watch( () => props.postcode, ( value: string ) => fetchStreetsForPostcode( value ) );
watch( streetNameModel, onUpdateModel );

const onBuildingNumberBlur = (): void => {
	buildingNumberWasBlurred.value = true;
	emit( 'field-changed' );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-field form-field-autocomplete", { 'is-invalid': _ctx.showError }])
    }, [
      _createElementVNode("label", {
        for: _ctx.inputIdStreetName,
        class: "form-field-label"
      }, _toDisplayString(_ctx.$t( 'donation_form_street_name_label' )), 9, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(TextFormInput, {
          modelValue: streetNameModel.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((streetNameModel).value = $event)),
          "input-type": "text",
          name: "street",
          placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_street_name_placeholder' ) } ),
          "has-error": _ctx.showError,
          "has-message": false,
          "input-id": _ctx.inputIdStreetName,
          onFocus: onStreetNameFocus,
          onBlur: onStreetNameBlur,
          onKeydown: [
            onStreetNameKeydown,
            _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (onStreetNameKeyArrows( 'up' )), ["prevent"]), ["up"])),
            _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (onStreetNameKeyArrows( 'down' )), ["prevent"]), ["down"])),
            _withKeys(onStreetNameKeySubmit, ["tab"]),
            _withKeys(onStreetNameKeySubmit, ["enter"])
          ],
          "aria-describedby": _unref(ariaDescribedby),
          "aria-autocomplete": "list"
        }, null, 8, ["modelValue", "placeholder", "has-error", "input-id", "aria-describedby"]),
        _createElementVNode("span", {
          class: "is-sr-only",
          id: `${_ctx.inputIdStreetName}-selected`,
          "aria-live": "assertive"
        }, _toDisplayString(activeStreet.value), 9, _hoisted_3),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "dropdown-content",
                ref_key: "scrollElement",
                ref: scrollElement,
                tabindex: "-1"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredStreets.value, (street) => {
                  return (_openBlock(), _createElementBlock("a", {
                    class: _normalizeClass(["dropdown-item", { 'is-active-item': street === activeStreet.value }]),
                    key: street,
                    role: "button",
                    tabindex: "-1",
                    onClick: _withModifiers(($event: any) => (onSelectStreet( street )), ["stop"]),
                    onKeyup: _withKeys(($event: any) => (onSelectStreet( street )), ["enter","space"])
                  }, _toDisplayString(street), 43, _hoisted_5))
                }), 128))
              ], 512)
            ], 512), [
              [_vShow, autocompleteIsActive.value && filteredStreets.value.length > 0]
            ])
          ]),
          _: 1
        })
      ]),
      (_ctx.showError)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "help is-danger",
            id: `${_ctx.inputIdStreetName}-error`
          }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_6))
        : _createCommentVNode("", true),
      _createVNode(ValueEqualsPlaceholderWarning, {
        value: streetNameModel.value,
        placeholder: _ctx.$t( 'donation_form_street_placeholder' ),
        warning: 'donation_form_street_placeholder_warning'
      }, null, 8, ["value", "placeholder"])
    ], 2),
    _createVNode(TextField, {
      name: "building-number",
      "input-id": _ctx.inputIdBuildingNumber,
      modelValue: buildingNumberModel.value,
      "onUpdate:modelValue": [
        _cache[3] || (_cache[3] = ($event: any) => ((buildingNumberModel).value = $event)),
        onUpdateModel
      ],
      "show-error": false,
      "error-message": _ctx.$t('donation_form_building_number_error'),
      label: _ctx.$t( 'donation_form_building_number_label' ),
      placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_building_number_placeholder' ) } ),
      onFieldChanged: onBuildingNumberBlur
    }, {
      message: _withCtx(() => [
        (showBuildingNumberWarning.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t( 'donation_form_street_number_warning' )), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["input-id", "modelValue", "error-message", "label", "placeholder"])
  ], 64))
}
}

})