import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  href: "#content",
  class: "skip-link"
}
const _hoisted_2 = {
  class: "main-wrapper",
  id: "content"
}
const _hoisted_3 = { class: "container" }

import AppHeader from '@src/components/layout/AppHeader.vue';
import AppContent from '@src/components/layout/AppContent.vue';
import AppSidebar from '@src/components/layout/AppSidebar.vue';
import AppFooter from '@src/components/layout/AppFooter.vue';
import { useI18n } from 'vue-i18n';
import { Component, onMounted, watch } from 'vue';
import { ModalStates, useModalState } from '@src/components/shared/composables/useModalState';
import { setModalClosed, setModalOpened } from '@src/util/modalPageFreezer';

interface Props {
	assetsPath: string;
	pageIdentifier: string;
	page: Component;
	pageProps?: Record<string, any>;
	pageTitle: string;
	isFullWidth?: boolean;
	usesContentCards?: boolean;
	bucketClasses?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  props: {
    assetsPath: {},
    pageIdentifier: {},
    page: {},
    pageProps: {},
    pageTitle: {},
    isFullWidth: { type: Boolean, default: false },
    usesContentCards: { type: Boolean, default: false },
    bucketClasses: { default: () => [] }
  },
  setup(__props: any) {

const props = __props;

const modalState = useModalState();

onMounted( () => {
	const { t } = useI18n();
	document.title = t( 'site_name', { pageTitle: t( props.pageTitle ) } );
} );

watch( modalState, ( newModalState: ModalStates ) => {
	switch ( newModalState ) {
		case ModalStates.Open:
			setModalOpened();
			break;
		case ModalStates.Closed:
			setModalClosed();
			break;
	}
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-container", _ctx.bucketClasses])
  }, [
    _createElementVNode("header", null, [
      _createElementVNode("a", _hoisted_1, _toDisplayString(_ctx.$t( 'skip_link_text' )), 1),
      _createVNode(AppHeader, {
        "page-identifier": _ctx.pageIdentifier,
        "assets-path": _ctx.assetsPath
      }, null, 8, ["page-identifier", "assets-path"])
    ]),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(AppContent, {
          "is-full-width": _ctx.isFullWidth,
          "uses-content-cards": _ctx.usesContentCards
        }, {
          content: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.page), _normalizeProps(_guardReactiveProps(_ctx.pageProps)), null, 16))
          ]),
          sidebar: _withCtx(() => [
            _createVNode(AppSidebar)
          ]),
          _: 1
        }, 8, ["is-full-width", "uses-content-cards"])
      ])
    ]),
    _createVNode(AppFooter, {
      "page-identifier": _ctx.pageIdentifier,
      "assets-path": _ctx.assetsPath
    }, null, 8, ["page-identifier", "assets-path"])
  ], 2))
}
}

})