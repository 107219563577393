import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import RadioField from '@src/components/shared/form_fields/RadioField.vue';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { ref, watch } from 'vue';
import RadioFieldHelpText from '@src/components/shared/form_elements/RadioFieldTooltip.vue';

interface Props {
	disabledAddressTypes: AddressTypeModel[];
	isDirectDebit: boolean;
	addressTypeIsInvalid: boolean;
	initialAddressType?: AddressTypeModel;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AddressTypeBasic',
  props: {
    disabledAddressTypes: {},
    isDirectDebit: { type: Boolean },
    addressTypeIsInvalid: { type: Boolean },
    initialAddressType: {}
  },
  emits: [ 'address-type' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;

const emit = __emit;

const addressType = ref<AddressTypeModel>( props.initialAddressType ?? AddressTypeModel.UNSET );

watch( addressType, newAddressType => {
	emit( 'address-type', newAddressType );
} );

// When disabled address type is selected, revert to person type
watch( () => props.disabledAddressTypes, disabledAddressTypes => {
	// TODO This assumes that person will never be disabled.
	//      If this assumption is wrong, we need to have a fallback.
	if ( disabledAddressTypes !== undefined &&
			disabledAddressTypes.includes( addressType.value ) ) {
		addressType.value = AddressTypeModel.PERSON;
	}
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RadioField, {
    name: "addressType",
    options: [
			{
				value: _unref(AddressTypeModel).PERSON,
				label: _ctx.$t( 'donation_form_addresstype_option_private_addresstype_basic' ),
				id: 'addressType-0'
			},
			{
				value: _unref(AddressTypeModel).COMPANY,
				label: _ctx.$t( 'donation_form_addresstype_option_company_addresstype_basic' ),
				id: 'addressType-1'
			},
			{
				value: _unref(AddressTypeModel).ANON,
				label: _ctx.$t( 'donation_form_addresstype_option_anonymous_addresstype_basic' ),
				id: 'addressType-2'
			},
		],
    label: _ctx.$t( 'donation_form_address_choice_title_addresstype_basic' ),
    disabled: _ctx.disabledAddressTypes,
    "show-error": _ctx.addressTypeIsInvalid,
    "error-message": _ctx.$t( 'donation_form_section_address_error' ),
    modelValue: addressType.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addressType).value = $event)),
    alignment: "column"
  }, {
    "tooltip-4": _withCtx(() => [
      (_ctx.isDirectDebit)
        ? (_openBlock(), _createBlock(RadioFieldHelpText, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_address_choice_direct_debit_disclaimer_addresstype_basic')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["options", "label", "disabled", "show-error", "error-message", "modelValue"]))
}
}

})