import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label", "aria-expanded"]
const _hoisted_2 = ["lang"]
const _hoisted_3 = { class: "navigation-locale-button-container" }

import { computed, ref } from 'vue';
import Cookies from 'js-cookie';
import { COOKIE_NAME, DEFAULT_LOCALE, LOCALES, LocaleSelectorItem } from '@src/util/createLocalisation';
import ChevronDown from '@src/components/shared/icons/ChevronDown.vue';
import LocaleIcon from '@src/components/shared/icons/LocaleIcon.vue';
import RadioFormInput from '@src/components/shared/form_elements/RadioFormInput.vue';
import { useDetectOutsideClick } from '@src/components/shared/composables/useDetectOutsideClick';
import FormButton from '@src/components/shared/form_elements/FormButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocaleSelector',
  setup(__props) {

const FOCUSABLE_ELEMENTS = [
	'navigation-locale-toggle',
	'navigation-locale-input',
	'navigation-locale-label',
	'navigation-locale-button',
];

const localeSelectorRef = ref<HTMLElement>();
const locale = ref<string>( Cookies.get( COOKIE_NAME ) ?? DEFAULT_LOCALE );
const localeSelectorItem = computed<LocaleSelectorItem>( () => LOCALES.find( x => x.value === locale.value ) );
const toggleText = localeSelectorItem.value.abbreviation;
const active = ref<boolean>( false );

const updateCookie = (): void => {
	Cookies.set( COOKIE_NAME, locale.value );
	window.location.reload();
};

const handleLocaleItemBlur = () => {
	if ( !active.value ) {
		return;
	}
	// Use setTimeout to wait for a little bit because it takes >100ms
	// after clicking a label for the activeElement to change
	setTimeout( () => {
		const focusedElementClasses = document.activeElement.classList;
		if ( focusedElementClasses && FOCUSABLE_ELEMENTS.filter( value => focusedElementClasses.contains( value ) ).length > 0 ) {
			return;
		}

		// Check if the user has selected some text
		const selectedTextElementClasses = document.getSelection()?.anchorNode?.parentElement?.classList;
		if ( selectedTextElementClasses && FOCUSABLE_ELEMENTS.filter( value => selectedTextElementClasses.contains( value ) ).length > 0 ) {
			return;
		}

		active.value = false;
	}, 400 );
};

useDetectOutsideClick( localeSelectorRef, handleLocaleItemBlur );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["navigation-locale", { 'active': active.value }]),
    ref_key: "localeSelectorRef",
    ref: localeSelectorRef
  }, [
    _createElementVNode("button", {
      class: "navigation-locale-toggle",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (active.value = !active.value)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (active.value = false), ["esc"])),
      onBlur: handleLocaleItemBlur,
      "aria-controls": "navigation-locale-dropdown",
      "aria-label": active.value ? _ctx.$t( 'aria_locale_toggle_label_open' ) : _ctx.$t( 'aria_locale_toggle_label_closed' ),
      "aria-expanded": active.value
    }, [
      _createVNode(LocaleIcon),
      _createTextVNode(" " + _toDisplayString(_unref(toggleText)) + " ", 1),
      _createVNode(ChevronDown)
    ], 40, _hoisted_1),
    _createElementVNode("form", {
      class: "navigation-locale-dropdown",
      id: "navigation-locale-dropdown",
      onSubmit: _withModifiers(updateCookie, ["prevent"])
    }, [
      _createElementVNode("fieldset", null, [
        _createElementVNode("legend", {
          class: "is-sr-only",
          lang: localeSelectorItem.value.value
        }, _toDisplayString(localeSelectorItem.value.helpText), 9, _hoisted_2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LOCALES), (localeItem, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "navigation-locale-item",
            key: index
          }, [
            _createVNode(RadioFormInput, {
              "input-type": "radio",
              id: `navigation-locale-item-${ localeItem.value }`,
              "input-class": "navigation-locale-input",
              "label-class": "navigation-locale-label",
              name: "locale",
              "native-value": localeItem.value,
              lang: localeItem.value,
              modelValue: locale.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((locale).value = $event)),
              onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (active.value = false), ["esc"])),
              onBlur: handleLocaleItemBlur
            }, {
              label: _withCtx(() => [
                _createTextVNode(_toDisplayString(localeItem.label), 1)
              ]),
              _: 2
            }, 1032, ["id", "native-value", "lang", "modelValue"])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(FormButton, {
            type: "submit",
            class: "navigation-locale-button",
            lang: locale.value,
            onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (active.value = false), ["esc"])),
            onBlur: handleLocaleItemBlur
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(localeSelectorItem.value.button), 1)
            ]),
            _: 1
          }, 8, ["lang"])
        ])
      ])
    ], 32)
  ], 2))
}
}

})