import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "payment-form" }

import { computed } from 'vue';
import { useStore } from 'vuex';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { AmountValidity } from '@src/view_models/Payment';
import { useI18n } from 'vue-i18n';
import AmountField from '@src/components/shared/form_fields/AmountField.vue';
import RadioField from '@src/components/shared/form_fields/RadioField.vue';
import { CheckboxFormOption } from '@src/components/shared/form_fields/FormOptions';
import { usePaymentFieldModel } from '@src/components/pages/donation_form/usePaymentFieldModel';
import { Validity } from '@src/view_models/Validity';
import FormSection from '@src/components/shared/form_elements/FormSection.vue';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import RadioFieldHelpText from '@src/components/shared/form_elements/RadioFieldTooltip.vue';
import { DisplaySectionCollection } from '@src/components/pages/donation_form/Payment';

interface Props {
	paymentAmounts: number[];
	paymentIntervals: number[];
	paymentTypes: string[];
	displaySections?: DisplaySectionCollection;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Payment',
  props: {
    paymentAmounts: {},
    paymentIntervals: {},
    paymentTypes: {},
    displaySections: { default: () => [ 'amount', 'interval', 'paymentType' ] }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const { t } = useI18n();

const amount = usePaymentFieldModel( store, 'amount', 'setAmount' );
const interval = usePaymentFieldModel( store, 'interval', 'setInterval' );
const paymentType = usePaymentFieldModel( store, 'type', 'setType' );
const paymentTypeIsValid = computed<boolean>( () => store.state.payment.validity.type !== Validity.INVALID );

const paymentIntervalsAsOptions = computed<CheckboxFormOption[]>( () => {
	return props.paymentIntervals.map(
		( intervalValue: number, index: number ) => (
			{ value: intervalValue.toString(), label: t( 'donation_form_payment_interval_' + intervalValue ), id: `interval-${ index }` }
		) );
} );

const paymentTypesAsOptions = computed<CheckboxFormOption[]>( () => {
	return props.paymentTypes.map(
		( paymentTypeValue: string, index: number ) => ( { value: paymentTypeValue, label: t( paymentTypeValue ), id: `paymentType-${ index }` } )
	);
} );

const disabledPaymentTypes = computed<string[]>( () => {
	let disabledTypes: string[] = [];
	if ( store.state.address.addressType === AddressTypeModel.ANON ) {
		disabledTypes.push( 'BEZ' );
	}
	if ( store.state.payment.values.interval !== '0' ) {
		disabledTypes.push( 'SUB' );
	}
	return disabledTypes;
} );

const disabledPaymentIntervals = computed<string[]>( () => {
	let disabledIntervals: string[] = [];
	if ( store.state.payment.values.type === 'SUB' ) {
		disabledIntervals = props.paymentIntervals
			.filter( ( x: number ) => Number( x ) > 0 )
			.map( ( x: number ) => String( x ) );
	}
	return disabledIntervals;
} );

const amountErrorMessage = computed<String>( () => {
	const messages: { [ key: number ]: string } = {
		[ AmountValidity.AMOUNT_VALID ]: '',
		[ AmountValidity.AMOUNT_TOO_LOW ]: t( 'donation_form_payment_amount_error' ),
		[ AmountValidity.AMOUNT_TOO_HIGH ]: t( 'donation_form_payment_amount_too_high' ),
	};
	return messages[ store.getters[ 'payment/amountValidity' ] ];
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displaySections.includes( 'amount' ))
      ? (_openBlock(), _createBlock(FormSection, {
          key: 0,
          id: "payment-form-amount"
        }, {
          default: _withCtx(() => [
            _createVNode(ScrollTarget, { "target-id": "payment-form-amount-scroll-target" }),
            _createVNode(AmountField, {
              modelValue: _unref(amount),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(amount) ? (amount).value = $event : null)),
              label: _ctx.$t('donation_form_payment_amount_title'),
              "payment-amounts": _ctx.paymentAmounts,
              "error-message": amountErrorMessage.value,
              "show-error": amountErrorMessage.value !== ''
            }, null, 8, ["modelValue", "label", "payment-amounts", "error-message", "show-error"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.displaySections.includes( 'interval' ))
      ? (_openBlock(), _createBlock(FormSection, {
          key: 1,
          id: "payment-form-interval"
        }, {
          default: _withCtx(() => [
            _createVNode(RadioField, {
              name: "interval",
              "input-id": "interval",
              modelValue: _unref(interval),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(interval) ? (interval).value = $event : null)),
              label: _ctx.$t('donation_form_payment_interval_title'),
              options: paymentIntervalsAsOptions.value,
              required: true,
              disabled: disabledPaymentIntervals.value,
              alignment: "twocolumnsperrow"
            }, null, 8, ["modelValue", "label", "options", "disabled"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.displaySections.includes( 'paymentType' ))
      ? (_openBlock(), _createBlock(FormSection, {
          key: 2,
          id: "payment-form-type"
        }, {
          default: _withCtx(() => [
            _createVNode(ScrollTarget, { "target-id": "payment-form-type-scroll-target" }),
            _createVNode(RadioField, {
              name: "paymentType",
              "input-id": "paymentType",
              modelValue: _unref(paymentType),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(paymentType) ? (paymentType).value = $event : null)),
              label: _ctx.$t('donation_form_payment_type_title'),
              options: paymentTypesAsOptions.value,
              required: true,
              disabled: disabledPaymentTypes.value,
              alignment: "twocolumnsperrow",
              "show-error": !paymentTypeIsValid.value,
              "error-message": _ctx.$t('donation_form_payment_type_error')
            }, {
              "tooltip-BEZ": _withCtx(() => [
                (disabledPaymentTypes.value.includes( 'BEZ' ))
                  ? (_openBlock(), _createBlock(RadioFieldHelpText, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_address_choice_direct_debit_disclaimer' )), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              "tooltip-SUB": _withCtx(() => [
                (disabledPaymentTypes.value.includes( 'SUB' ))
                  ? (_openBlock(), _createBlock(RadioFieldHelpText, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_SUB_payment_type_info' )), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "label", "options", "disabled", "show-error", "error-message"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})