import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["id"]

import { SelectFormOption } from '@src/components/shared/form_fields/FormOptions';
import { useFieldModel } from '@src/components/shared/form_fields/useFieldModel';
import SelectFormInput from '@src/components/shared/form_elements/SelectFormInput.vue';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';
import { computed } from 'vue';

interface Props {
	label: String;
	name: string;
	inputId: string;
	modelValue: string | number;
	options: SelectFormOption[];
	errorMessage?: String;
	showError?: boolean;
	ariaDescribedby?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectField',
  props: {
    label: {},
    name: {},
    inputId: {},
    modelValue: {},
    options: {},
    errorMessage: {},
    showError: { type: Boolean },
    ariaDescribedby: { default: '' }
  },
  emits: [ 'update:modelValue', 'field-changed' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const fieldModel = useFieldModel<string | number>( () => props.modelValue, props.modelValue );
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => props.ariaDescribedby ),
	`${props.inputId}-error`,
	computed<boolean>( () => props.showError )
);

const onFieldChange = ( newValue: string | number ): void => {
	emit( 'update:modelValue', newValue );
	emit( 'field-changed', props.name );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field form-field-select", { 'is-invalid': _ctx.showError }])
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: "form-field-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createVNode(SelectFormInput, {
      modelValue: _unref(fieldModel),
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldModel) ? (fieldModel).value = $event : null)),
        onFieldChange
      ],
      "select-id": _ctx.inputId,
      name: _ctx.name,
      "has-error": _ctx.showError,
      "aria-describedby": _unref(ariaDescribedby)
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: option.value
          }, _toDisplayString(option.label), 9, _hoisted_2))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "select-id", "name", "has-error", "aria-describedby"]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "help is-danger",
          id: `${_ctx.inputId}-error`
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}
}

})