import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "name-section" }

import { Salutation } from '@src/view_models/Salutation';
import { AddressFormData, AddressValidity } from '@src/view_models/Address';
import ValueEqualsPlaceholderWarning from '@src/components/shared/ValueEqualsPlaceholderWarning.vue';
import RadioField from '@src/components/shared/form_fields/RadioField.vue';
import SelectField from '@src/components/shared/form_fields/SelectField.vue';
import TextField from '@src/components/shared/form_fields/TextField.vue';
import { computed } from 'vue';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { CheckboxFormOption } from '@src/components/shared/form_fields/FormOptions';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';

interface Props {
	addressType: AddressTypeModel;
	salutations: Salutation[];
	formData: AddressFormData;
	showError: AddressValidity;
	fieldIdNamespace?: string;
	addressTypesToShowPersonalFields?: AddressTypeModel[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NameFields',
  props: {
    addressType: {},
    salutations: {},
    formData: {},
    showError: {},
    fieldIdNamespace: {},
    addressTypesToShowPersonalFields: { default: () => [
		AddressTypeModel.PERSON,
		AddressTypeModel.EMAIL,
		AddressTypeModel.UNSET,
	] }
  },
  emits: [ 'field-changed' ],
  setup(__props: any) {


const props = __props;


const showPersonalFields = computed( () => props.addressTypesToShowPersonalFields.includes( props.addressType ) );
const showCompanyFields = computed( () => props.addressType === AddressTypeModel.COMPANY );
const fieldIdNamespace = props.fieldIdNamespace ? `${props.fieldIdNamespace}-` : '';
const salutationFormOptions: CheckboxFormOption[] = props.salutations.map( ( x, index ) => (
	{ value: x.value, label: x.label, id: `${ fieldIdNamespace }salutation-${ index }` }
) );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}salutation-scroll-target`
    }, null, 8, ["target-id"]),
    (showPersonalFields.value)
      ? (_openBlock(), _createBlock(RadioField, {
          key: 0,
          name: "salutation",
          modelValue: _ctx.formData.salutation.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.salutation.value) = $event)),
          label: _ctx.$t( 'donation_form_salutation_label' ),
          options: _unref(salutationFormOptions),
          "show-error": _ctx.showError.salutation,
          "error-message": _ctx.$t( 'donation_form_salutation_error' ),
          onFieldChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('field-changed', 'salutation'))),
          alignment: "column"
        }, null, 8, ["modelValue", "label", "options", "show-error", "error-message"]))
      : _createCommentVNode("", true),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}title-scroll-target`
    }, null, 8, ["target-id"]),
    (showPersonalFields.value)
      ? (_openBlock(), _createBlock(SelectField, {
          key: 1,
          name: "title",
          "input-id": `${_unref(fieldIdNamespace)}title`,
          modelValue: _ctx.formData.title.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.title.value) = $event)),
          label: _ctx.$t( 'donation_form_academic_title_label' ),
          options: [
				{ label: _ctx.$t( 'donation_form_academic_title_option_none' ), value: '' },
				{ label: 'Dr.', value: 'Dr.' },
				{ label: 'Prof.', value: 'Prof.' },
				{ label: 'Prof. Dr.', value: 'Prof. Dr.' },
			],
          onFieldChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('field-changed', 'title')))
        }, null, 8, ["input-id", "modelValue", "label", "options"]))
      : _createCommentVNode("", true),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}first-name-scroll-target`
    }, null, 8, ["target-id"]),
    (showPersonalFields.value)
      ? (_openBlock(), _createBlock(TextField, {
          key: 2,
          name: "firstName",
          "input-id": `${_unref(fieldIdNamespace)}first-name`,
          modelValue: _ctx.formData.firstName.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.firstName.value) = $event)),
          "show-error": _ctx.showError.firstName,
          "error-message": _ctx.$t( 'donation_form_firstname_error' ),
          autocomplete: "given-name",
          label: _ctx.$t( 'donation_form_firstname_label' ),
          placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_firstname_placeholder' ) } ),
          onFieldChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('field-changed', 'firstName')))
        }, null, 8, ["input-id", "modelValue", "show-error", "error-message", "label", "placeholder"]))
      : _createCommentVNode("", true),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}last-name-scroll-target`
    }, null, 8, ["target-id"]),
    (showPersonalFields.value)
      ? (_openBlock(), _createBlock(TextField, {
          key: 3,
          name: "lastName",
          id: `${_unref(fieldIdNamespace)}last-name-container`,
          "input-id": `${_unref(fieldIdNamespace)}last-name`,
          modelValue: _ctx.formData.lastName.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.lastName.value) = $event)),
          "show-error": _ctx.showError.lastName,
          "error-message": _ctx.$t( 'donation_form_lastname_error' ),
          autocomplete: "family-name",
          label: _ctx.$t( 'donation_form_lastname_label' ),
          placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_lastname_placeholder' ) } ),
          onFieldChanged: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('field-changed', 'lastName')))
        }, {
          message: _withCtx(() => [
            _createVNode(ValueEqualsPlaceholderWarning, {
              value: _ctx.formData.lastName.value,
              placeholder: _ctx.$t( 'donation_form_lastname_placeholder_check' ),
              warning: 'donation_form_lastname_placeholder_warning'
            }, null, 8, ["value", "placeholder"])
          ]),
          _: 1
        }, 8, ["id", "input-id", "modelValue", "show-error", "error-message", "label", "placeholder"]))
      : _createCommentVNode("", true),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}company-name-scroll-target`
    }, null, 8, ["target-id"]),
    (showCompanyFields.value)
      ? (_openBlock(), _createBlock(TextField, {
          key: 4,
          name: "companyName",
          "input-id": `${_unref(fieldIdNamespace)}company-name`,
          modelValue: _ctx.formData.companyName.value,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.companyName.value) = $event)),
          "show-error": _ctx.showError.companyName,
          "error-message": _ctx.$t( 'donation_form_companyname_error' ),
          autocomplete: "organization",
          label: _ctx.$t( 'donation_form_companyname_label' ),
          placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_companyname_placeholder' ) } ),
          onFieldChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('field-changed', 'companyName')))
        }, null, 8, ["input-id", "modelValue", "show-error", "error-message", "label", "placeholder"]))
      : _createCommentVNode("", true)
  ]))
}
}

})