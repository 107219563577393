import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ErrorSummary from '@src/components/shared/validation_summary/ErrorSummary.vue';
import { useStore } from 'vuex';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { Validity } from '@src/view_models/Validity';
import { ReceiptModel } from '@src/components/shared/composables/useReceiptModel';
import { toRef } from 'vue';

interface Props {
	showErrorSummary: boolean;
	addressType: AddressTypeModel;
	receiptModel: ReceiptModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorSummary',
  props: {
    showErrorSummary: { type: Boolean },
    addressType: {},
    receiptModel: {}
  },
  setup(__props: any) {


const props = __props;
const store = useStore();
const receiptModel = toRef<ReceiptModel>( props.receiptModel );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!receiptModel.value.receiptNeeded && _ctx.addressType === _unref(AddressTypeModel).UNSET)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 0,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.salutation,
				message: _ctx.$t( 'donation_form_salutation_error' ),
				focusElement: 'salutation-0',
				scrollElement: 'salutation-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.firstName,
				message: _ctx.$t( 'donation_form_firstname_error' ),
				focusElement: 'first-name',
				scrollElement: 'first-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.lastName,
				message: _ctx.$t( 'donation_form_lastname_error' ),
				focusElement: 'last-name',
				scrollElement: 'last-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'email',
				scrollElement: 'email-scroll-target'
			},
			{
				validity: receiptModel.value.showReceiptOptionError ? _unref(Validity).INVALID : _unref(Validity).VALID,
				message: _ctx.$t( 'C24_WMDE_Desktop_DE_01_receipt_error' ),
				focusElement: 'donationReceipt-0',
				scrollElement: 'receipt-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true),
    (( receiptModel.value.receiptNeeded && _ctx.addressType === _unref(AddressTypeModel).UNSET ) || _ctx.addressType === _unref(AddressTypeModel).PERSON)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 1,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.salutation,
				message: _ctx.$t( 'donation_form_salutation_error' ),
				focusElement: 'salutation-0',
				scrollElement: 'salutation-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.firstName,
				message: _ctx.$t( 'donation_form_firstname_error' ),
				focusElement: 'first-name',
				scrollElement: 'first-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.lastName,
				message: _ctx.$t( 'donation_form_lastname_error' ),
				focusElement: 'last-name',
				scrollElement: 'last-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'email',
				scrollElement: 'email-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.addressType,
				message: _ctx.$t( 'donation_form_section_address_error' ),
				focusElement: 'addressType-0',
				scrollElement: 'address-type-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.country,
				message: _ctx.$t( 'donation_form_country_error' ),
				focusElement: 'country',
				scrollElement: 'country-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.postcode,
				message: _ctx.$t( 'donation_form_zip_error' ),
				focusElement: 'post-code',
				scrollElement: 'post-code-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.city,
				message: _ctx.$t( 'donation_form_city_error' ),
				focusElement: 'city',
				scrollElement: 'city-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.street,
				message: _ctx.$t( 'donation_form_street_error' ),
				focusElement: 'street',
				scrollElement: 'street-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true),
    (_ctx.addressType === _unref(AddressTypeModel).COMPANY_WITH_CONTACT)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 2,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.salutation,
				message: _ctx.$t( 'donation_form_salutation_error' ),
				focusElement: 'salutation-0',
				scrollElement: 'salutation-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.firstName,
				message: _ctx.$t( 'donation_form_firstname_error' ),
				focusElement: 'first-name',
				scrollElement: 'first-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.lastName,
				message: _ctx.$t( 'donation_form_lastname_error' ),
				focusElement: 'last-name',
				scrollElement: 'last-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'email',
				scrollElement: 'email-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.addressType,
				message: _ctx.$t( 'donation_form_section_address_error' ),
				focusElement: 'addressType-0',
				scrollElement: 'address-type-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.companyName,
				message: _ctx.$t( 'donation_form_companyname_error' ),
				focusElement: 'company-name',
				scrollElement: 'company-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.country,
				message: _ctx.$t( 'donation_form_country_error' ),
				focusElement: 'country',
				scrollElement: 'country-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.postcode,
				message: _ctx.$t( 'donation_form_zip_error' ),
				focusElement: 'post-code',
				scrollElement: 'post-code-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.city,
				message: _ctx.$t( 'donation_form_city_error' ),
				focusElement: 'city',
				scrollElement: 'city-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.street,
				message: _ctx.$t( 'donation_form_street_error' ),
				focusElement: 'street',
				scrollElement: 'street-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true),
    (_ctx.addressType === _unref(AddressTypeModel).EMAIL)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 3,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.salutation,
				message: _ctx.$t( 'donation_form_salutation_error' ),
				focusElement: 'salutation-0',
				scrollElement: 'salutation-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.firstName,
				message: _ctx.$t( 'donation_form_firstname_error' ),
				focusElement: 'first-name',
				scrollElement: 'first-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.lastName,
				message: _ctx.$t( 'donation_form_lastname_error' ),
				focusElement: 'last-name',
				scrollElement: 'last-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'email',
				scrollElement: 'email-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})