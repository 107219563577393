import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "donation-page-form-section-personal-data",
  class: "donation-page-form-section",
  "aria-live": "assertive",
  "aria-labelledby": "donation-form-subheading donation-form-tagline"
}
const _hoisted_2 = {
  id: "donation-form-subheading",
  class: "form-subtitle"
}
const _hoisted_3 = { id: "donation-form-tagline" }

import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import AddressTypeBasic from '@src/components/pages/donation_form/AddressTypeBasic.vue';
import AddressForms from '@src/components/pages/donation_form/AddressForms.vue';
import { CampaignValues } from '@src/view_models/CampaignValues';
import { Country } from '@src/view_models/Country';
import { Salutation } from '@src/view_models/Salutation';
import { TrackingData } from '@src/view_models/TrackingData';
import { AddressValidation } from '@src/view_models/Validation';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';

interface Props {
	countries: Country[];
	salutations: Salutation[];
	trackingData: TrackingData;
	campaignValues: CampaignValues;
	addressValidationPatterns: AddressValidation;
	isDirectDebitPayment: boolean;
	disabledAddressTypes: AddressTypeModel[];
	addressType: AddressTypeModel;
	addressTypeIsInvalid: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalDataSection',
  props: {
    countries: {},
    salutations: {},
    trackingData: {},
    campaignValues: {},
    addressValidationPatterns: {},
    isDirectDebitPayment: { type: Boolean },
    disabledAddressTypes: {},
    addressType: {},
    addressTypeIsInvalid: { type: Boolean }
  },
  emits: [ 'set-address-type' ],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t( 'donation_form_address_subheading' )), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t( 'donation_form_section_address_tagline' )), 1),
    _createElementVNode("form", {
      id: "address-type-selection",
      onSubmit: _cache[1] || (_cache[1] = evt => evt.preventDefault())
    }, [
      _createVNode(ScrollTarget, { "target-id": "address-type-scroll-target" }),
      _createVNode(AddressTypeBasic, {
        onAddressType: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit( 'set-address-type', $event ))),
        disabledAddressTypes: _ctx.disabledAddressTypes,
        "is-direct-debit": _ctx.isDirectDebitPayment,
        "initial-address-type": _ctx.addressType,
        "address-type-is-invalid": _ctx.addressTypeIsInvalid
      }, null, 8, ["disabledAddressTypes", "is-direct-debit", "initial-address-type", "address-type-is-invalid"])
    ], 32),
    _createVNode(AddressForms, {
      countries: _ctx.countries,
      salutations: _ctx.salutations,
      "address-validation-patterns": _ctx.addressValidationPatterns,
      "address-type": _ctx.addressType,
      "tracking-data": _ctx.trackingData,
      "campaign-values": _ctx.campaignValues
    }, null, 8, ["countries", "salutations", "address-validation-patterns", "address-type", "tracking-data", "campaign-values"])
  ]))
}
}

})