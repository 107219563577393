import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bank-data-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "bank-transfer-code" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "bank-transfer-code" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
	bankTransferCode?: String;
	bankTransferAmount?: String;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BankData',
  props: {
    bankTransferCode: {},
    bankTransferAmount: {}
  },
  setup(__props: any) {




const { t } = useI18n();
const ibanParts = computed( () => t( 'bank_data_operator_account_iban' ).split( ' ' ) );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'bank_data_account_owner' )) + ":", 1),
      _createTextVNode(" " + _toDisplayString(_ctx.$t( 'bank_data_operator_account_owner' )), 1)
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'bank_data_iban')) + ":", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ibanParts.value, (ibanPartial, index) => {
        return (_openBlock(), _createElementBlock("span", {
          class: "spacer",
          key: index
        }, _toDisplayString(ibanPartial), 1))
      }), 128))
    ]),
    _createElementVNode("li", null, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'bank_data_bic')) + ":", 1),
      _createTextVNode(" " + _toDisplayString(_ctx.$t( 'bank_data_operator_account_bic')), 1)
    ]),
    _createElementVNode("li", null, _toDisplayString(_ctx.$t( 'bank_data_operator_bank_name')), 1),
    (_ctx.bankTransferAmount)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'bank_data_transfer_amount_label' )) + ": ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.bankTransferAmount), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.bankTransferCode)
      ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'bank_data_transfer_code' )) + ": ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.bankTransferCode), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})