import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "name", "id", "disabled", "required", "aria-describedby"]
const _hoisted_2 = ["for"]

import { useInputModel } from '@src/components/shared/form_elements/useInputModel';

interface Props {
	modelValue: boolean | null;
	name: string;
	inputId: string;
	disabled?: boolean;
	required?: boolean;
	describedBy?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxSingleFormInput',
  props: {
    modelValue: { type: [Boolean, null] },
    name: {},
    inputId: {},
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    describedBy: {}
  },
  emits: [ 'update:modelValue' ],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const inputModel = useInputModel<boolean>( () => props.modelValue, props.modelValue, emit );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control checkbox-single-form-input", { 'is-disabled': _ctx.disabled }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["checkbox", { 'is-disabled': _ctx.disabled }])
    }, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputModel) ? (inputModel).value = $event : null)),
        value: _unref(inputModel),
        type: "checkbox",
        name: _ctx.name,
        id: _ctx.inputId,
        disabled: _ctx.disabled,
        required: _ctx.required,
        "aria-describedby": _ctx.describedBy
      }, null, 8, _hoisted_1), [
        [_vModelCheckbox, _unref(inputModel)]
      ]),
      _createElementVNode("label", {
        class: "control-label",
        for: _ctx.inputId
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_2)
    ], 2)
  ], 2))
}
}

})