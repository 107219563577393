import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-section-iban" }
const _hoisted_2 = { class: "form-section-iban-title" }
const _hoisted_3 = { class: "form-subtitle" }
const _hoisted_4 = { class: "iban-calculator-content" }
const _hoisted_5 = { class: "icon-title" }
const _hoisted_6 = { class: "is-sr-only" }
const _hoisted_7 = {
  class: "iban-calculator-scroller",
  tabindex: "-1"
}
const _hoisted_8 = ["inert"]
const _hoisted_9 = ["inert"]
const _hoisted_10 = { id: "iban-calculator-results-label" }
const _hoisted_11 = { class: "iban-calculator-results-list" }
const _hoisted_12 = { class: "iban-calculator-results-buttons" }

import { Validity } from '@src/view_models/Validity';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import IbanField from '@src/components/shared/form_fields/IbanField.vue';
import ButtonLink from '@src/components/shared/ButtonLink.vue';
import TextField from '@src/components/shared/form_fields/TextField.vue';
import ErrorSummary from '@src/components/shared/validation_summary/ErrorSummary.vue';
import FormButton from '@src/components/shared/form_elements/FormButton.vue';
import BankIcon from '@src/components/shared/icons/BankIcon.vue';
import CloseIcon from '@src/components/shared/icons/CloseIcon.vue';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { BankValidationResource } from '@src/api/BankValidationResource';
import { useStore } from 'vuex';
import { BankAccountResponse } from '@src/view_models/BankAccount';
import { action } from '@src/store/util';

const calculatorPageTransitionMilliseconds = 300;

export default /*@__PURE__*/_defineComponent({
  __name: 'IbanFields',
  setup(__props) {


const bankValidationResource = inject<BankValidationResource>( 'bankValidationResource' );
const store = useStore();
const iban = ref<string>( store.state.bankdata.values.iban );
const bic = computed<string>( () => store.state.bankdata.values.bic );
const bankName = computed<string>( () => store.state.bankdata.values.bankName );
const showIbanError = computed<boolean>( () => store.state.bankdata.validity.iban === Validity.INVALID );

const showCalculator = ref<boolean>( false );
const calculatorPage = ref<1 | 2>( 1 );
const accountNumber = ref<string>( '' );
const bankCode = ref<string>( '' );
const accountNumberError = ref<boolean>( false );
const bankCodeError = ref<boolean>( false );
const isSearchingForIban = ref<boolean>( false );
const showCalculatorErrorSummary = ref<boolean>( false );
const resultsPanel = ref<HTMLElement>( null );
const foundIban = ref<string>( '' );
const foundBic = ref<string>( '' );
const foundBankName = ref<string>( '' );

const tryHideErrorSummary = () => {
	if ( !showCalculatorErrorSummary.value ) {
		return;
	}

	showCalculatorErrorSummary.value = accountNumberError.value || bankCodeError.value;
};

const validateAccountNumber = () => {
	accountNumberError.value = accountNumber.value === '';
	tryHideErrorSummary();
};

const validateBankCode = () => {
	bankCodeError.value = bankCode.value === '';
	tryHideErrorSummary();
};

const submitBankAccount = async () => {
	validateAccountNumber();
	validateBankCode();

	if ( accountNumberError.value || bankCodeError.value ) {
		showCalculatorErrorSummary.value = true;
		return;
	}
	isSearchingForIban.value = true;
	bankValidationResource.validateBankNumber( {
		accountNumber: accountNumber.value,
		bankCode: bankCode.value,
	} ).then( ( response: BankAccountResponse ) => {
		isSearchingForIban.value = false;
		accountNumber.value = response.accountNumber;
		bankCode.value = response.bankCode;
		foundIban.value = response.iban;
		foundBic.value = response.bic;
		foundBankName.value = response.bankName;
		calculatorPage.value = 2;

		// Focus panel after css transition has finished
		setTimeout( () => resultsPanel.value.focus(), calculatorPageTransitionMilliseconds );
	} ).catch( () => {
		isSearchingForIban.value = false;
		showCalculatorErrorSummary.value = true;
		accountNumberError.value = true;
		bankCodeError.value = true;
	} );
};

const validateIban = () => {
	store.dispatch( action( 'bankdata', 'setIban' ), iban.value );
	if ( iban.value === '' ) {
		store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.INVALID );
		store.dispatch( action( 'bankdata', 'setBic' ), '' );
		store.dispatch( action( 'bankdata', 'setBankName' ), '' );
		return;
	}

	bankValidationResource.validateIban( {
		iban: iban.value,
	} ).then( ( response: BankAccountResponse ) => {
		store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.VALID );
		store.dispatch( action( 'bankdata', 'setIban' ), response.iban );
		store.dispatch( action( 'bankdata', 'setBic' ), response.bic );
		store.dispatch( action( 'bankdata', 'setBankName' ), response.bankName );
	} ).catch( () => {
		store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.INVALID );
		store.dispatch( action( 'bankdata', 'setBic' ), '' );
		store.dispatch( action( 'bankdata', 'setBankName' ), '' );
	} );
};

const confirmResult = () => {
	store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.VALID );
	store.dispatch( action( 'bankdata', 'setIban' ), foundIban.value );
	store.dispatch( action( 'bankdata', 'setBic' ), foundBic.value );
	store.dispatch( action( 'bankdata', 'setBankName' ), foundBankName.value );
	showCalculator.value = false;
	calculatorPage.value = 1;
};

const tryAgain = () => {
	calculatorPage.value = 1;
};

onMounted( () => {
	if ( iban.value !== '' ) {
		validateIban();
	}
} );

watch( () => store.state.bankdata.values.iban, ( newIban: string ) => {
	iban.value = newIban;
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t( 'donation_form_payment_bankdata_title' )), 1),
      _createVNode(ButtonLink, {
        class: "calculate-iban-button",
        "aria-controls": "iban-calculator",
        "aria-expanded": showCalculator.value,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (showCalculator.value = !showCalculator.value))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_iban_calculator_button' )), 1)
        ]),
        _: 1
      }, 8, ["aria-expanded"])
    ]),
    _createElementVNode("form", {
      id: "iban-calculator",
      class: _normalizeClass(["iban-calculator", { 'visible': showCalculator.value }]),
      onSubmit: _withModifiers(submitBankAccount, ["prevent"]),
      style: _normalizeStyle({ '--iban-calculator-page-transition' : calculatorPageTransitionMilliseconds + 'ms' })
    }, [
      _createVNode(ScrollTarget, { "target-id": "iban-calculator-scroll-target" }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, [
          _createVNode(BankIcon),
          _createTextVNode(" " + _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_title' )), 1)
        ]),
        _createElementVNode("button", {
          class: "iban-calculator-close",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (showCalculator.value = false), ["prevent"])),
          "aria-controls": "iban-calculator"
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t( 'close' )), 1),
          _createVNode(CloseIcon)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["iban-calculator-pages", { 'page-2': calculatorPage.value === 2 }])
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "iban-calculator-page",
              inert: calculatorPage.value === 2,
              tabindex: "-1"
            }, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_help_text' )), 1),
              _createVNode(TextField, {
                modelValue: accountNumber.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((accountNumber).value = $event)),
                name: "account-number",
                "input-id": "account-number",
                label: _ctx.$t( 'donation_form_payment_bankdata_account_legacy_label' ),
                placeholder: "",
                "show-error": accountNumberError.value,
                "error-message": _ctx.$t( 'donation_form_account_number_error' ),
                onFieldChanged: validateAccountNumber
              }, null, 8, ["modelValue", "label", "show-error", "error-message"]),
              _createVNode(TextField, {
                modelValue: bankCode.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((bankCode).value = $event)),
                name: "bank-code",
                "input-id": "bank-code",
                label: _ctx.$t( 'donation_form_payment_bankdata_bank_legacy_label' ),
                placeholder: _ctx.$t( 'donation_form_payment_bankdata_bank_legacy_placeholder' ),
                "show-error": bankCodeError.value,
                "error-message": _ctx.$t( 'donation_form_bank_code_error' ),
                onFieldChanged: validateBankCode
              }, null, 8, ["modelValue", "label", "placeholder", "show-error", "error-message"]),
              _createVNode(ErrorSummary, {
                "is-visible": showCalculatorErrorSummary.value,
                items: [
								{
									validity: accountNumberError.value ? _unref(Validity).INVALID : _unref(Validity).VALID,
									message: _ctx.$t( 'donation_form_account_number_error' ),
									focusElement: 'account-number',
									scrollElement: 'iban-calculator-scroll-target'
								},
								{
									validity: bankCodeError.value ? _unref(Validity).INVALID : _unref(Validity).VALID,
									message: _ctx.$t( 'donation_form_bank_code_error' ),
									focusElement: 'bank-code',
									scrollElement: 'iban-calculator-scroll-target'
								}
							]
              }, null, 8, ["is-visible", "items"]),
              _createVNode(FormButton, {
                "button-type": "submit",
                "is-loading": isSearchingForIban.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_iban_calculator_button' )), 1)
                ]),
                _: 1
              }, 8, ["is-loading"])
            ], 8, _hoisted_8),
            _createElementVNode("div", {
              ref_key: "resultsPanel",
              ref: resultsPanel,
              class: "iban-calculator-page iban-calculator-results",
              "aria-labelledby": "iban-calculator-results-label",
              tabindex: "-1",
              inert: calculatorPage.value === 1
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_lead' )), 1),
                _createElementVNode("ul", _hoisted_11, [
                  _createElementVNode("li", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_bank_account' )), 1),
                    _createTextVNode(" " + _toDisplayString(accountNumber.value), 1)
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_bank_code' )), 1),
                    _createTextVNode(" " + _toDisplayString(bankCode.value), 1)
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_iban' )), 1),
                    _createTextVNode(" " + _toDisplayString(foundIban.value), 1)
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_bic' )), 1),
                    _createTextVNode(" " + _toDisplayString(foundBic.value), 1)
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_bank_name' )), 1),
                    _createTextVNode(" " + _toDisplayString(foundBankName.value), 1)
                  ])
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t( 'donation_form_iban_calculator_result_cta' )), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(FormButton, {
                  onClick: _withModifiers(confirmResult, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t( 'yes' )), 1)
                  ]),
                  _: 1
                }),
                _createVNode(FormButton, {
                  "is-outlined": true,
                  onClick: _withModifiers(tryAgain, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t( 'no' )), 1)
                  ]),
                  _: 1
                })
              ])
            ], 8, _hoisted_9)
          ])
        ], 2)
      ])
    ], 38),
    _createElementVNode("form", {
      class: "iban-form",
      onSubmit: _withModifiers(() => {}, ["prevent"])
    }, [
      _createVNode(ScrollTarget, { "target-id": "iban-scroll-target" }),
      _createVNode(IbanField, {
        modelValue: iban.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((iban).value = $event)),
        "bank-name": bankName.value,
        bic: bic.value,
        "show-error": showIbanError.value,
        onFieldChanged: validateIban
      }, null, 8, ["modelValue", "bank-name", "bic", "show-error"])
    ], 32)
  ]))
}
}

})