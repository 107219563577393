import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  for: "iban",
  class: "form-field-label"
}
const _hoisted_2 = ["placeholder", "aria-invalid", "aria-describedby"]
const _hoisted_3 = {
  key: 0,
  class: "help is-danger",
  id: "iban-error"
}
const _hoisted_4 = {
  key: 1,
  class: "field-info-message iban-bank-name"
}

import { nextTick, onMounted, ref, toRef, watch } from 'vue';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';

interface Props {
	modelValue: string;
	showError: boolean;
	bankName: string;
	bic: string;
	ariaDescribedby?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IbanField',
  props: {
    modelValue: {},
    showError: { type: Boolean },
    bankName: {},
    bic: {},
    ariaDescribedby: { default: '' }
  },
  emits: [ 'field-changed', 'update:modelValue' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const fieldModel = ref<string>( props.modelValue );
const field = ref<HTMLInputElement>( null );
const ariaDescribedby = useAriaDescribedby(
	toRef( (): string => props.ariaDescribedby ),
	'iban-error',
	toRef( (): boolean => props.showError )
);

const getDisplayValue = ( newValue: string ) => {
	return newValue.replace( /(.{4})/g, '$& ' ).trim();
};

/**
 * 1. Get the text before the cursor
 * 2. Clear the spaces
 * 3. Add new spaces in the correct positions, but don't trim
 * 4. Return the length
 *
 * @param newValue
 * @param cursorPosition
 */
const getNewCursorPosition = ( newValue: string, cursorPosition: number ): number => {
	return newValue
		.slice( 0, cursorPosition )
		.replace( /\s/g, '' )
		.replace( /(.{4})/g, '$& ' )
		.length;
};

const setFieldValueFromModel = () => {
	field.value.value = getDisplayValue( fieldModel.value );
};

const onInput = async (): Promise<void> => {
	const newValue = field.value.value;
	const cursorPosition = field.value.selectionStart;

	const value = newValue.replace( /\s/g, '' );
	const shouldUpdateFormattedNumber = value.length > fieldModel.value.length;
	fieldModel.value = value;
	await nextTick();

	// Only update the formatted number when the donor is entering text not deleting it
	if ( shouldUpdateFormattedNumber ) {
		setFieldValueFromModel();

		// When we replace the field value the browser jumps the cursor to the end
		// so we reset it after changing the value. This allows the donor to edit
		// their bank number if they spot a mistake.
		const newCursorPosition = getNewCursorPosition( newValue, cursorPosition );
		field.value.setSelectionRange( newCursorPosition, newCursorPosition );
	}

	emit( 'update:modelValue', fieldModel.value );
};

const onBlur = (): void => {
	setFieldValueFromModel();
	emit( 'field-changed', 'iban' );
};

onMounted( () => {
	setFieldValueFromModel();
} );

watch( () => props.modelValue, ( newModelValue: string ) => {
	if ( fieldModel.value !== newModelValue ) {
		fieldModel.value = newModelValue;
		setFieldValueFromModel();
	}
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field form-field-iban", [ 'form-field-text', { 'is-invalid': _ctx.showError } ]])
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t( 'donation_form_payment_bankdata_account_iban_label' )), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["control text-form-input", { 'has-icons-right': _ctx.showError }])
    }, [
      _createElementVNode("input", {
        ref_key: "field",
        ref: field,
        name: "iban",
        class: _normalizeClass(["input", { 'is-danger': _ctx.showError }]),
        id: "iban",
        type: "text",
        autocomplete: "on",
        placeholder: _ctx.$t( 'donation_form_payment_bankdata_account_iban_placeholder' ),
        "aria-invalid": _ctx.showError,
        "aria-describedby": _unref(ariaDescribedby),
        onBlur: onBlur,
        onInput: onInput,
        onPaste: onInput
      }, null, 42, _hoisted_2)
    ], 2),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t( 'donation_form_payment_iban_error' )), 1))
      : _createCommentVNode("", true),
    (_ctx.modelValue !== '' && _ctx.bankName)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.bankName) + " (" + _toDisplayString(_ctx.bic) + ") ", 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})