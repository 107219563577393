import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-field form-field-mailing-list" }
const _hoisted_2 = ["innerHTML"]

import CheckboxSingleFormInput from '@src/components/shared/form_elements/CheckboxSingleFormInput.vue';
import { useFieldModel } from '@src/components/shared/form_fields/useFieldModel';
import { appendCampaignQueryParams } from '@src/util/append_campaign_query_params';
import { inject } from 'vue';
import { QUERY_STRING_INJECTION_KEY } from '@src/util/createCampaignQueryString';

interface Props {
	modelValue: boolean;
	inputId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MailingListField',
  props: {
    modelValue: { type: Boolean },
    inputId: {}
  },
  emits: [ 'update:modelValue' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const fieldModel = useFieldModel<boolean>( () => props.modelValue, props.modelValue );

const onUpdateModel = ( newValue: boolean ): void => {
	emit( 'update:modelValue', newValue );
};

const campaignParams = inject<string>( QUERY_STRING_INJECTION_KEY, '' );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CheckboxSingleFormInput, {
      modelValue: _unref(fieldModel),
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldModel) ? (fieldModel).value = $event : null)),
        onUpdateModel
      ],
      name: "info",
      "input-id": _ctx.inputId,
      "described-by": "mailing-list-hint"
    }, {
      default: _withCtx(() => [
        _createElementVNode("strong", null, _toDisplayString(_ctx.$t( 'donation_form_newsletter_label_paragraph_1' )), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "input-id"]),
    _createElementVNode("p", {
      id: "mailing-list-hint",
      class: "form-field-mailing-list-hint",
      innerHTML: _unref(appendCampaignQueryParams)( _ctx.$t( 'donation_form_newsletter_label_paragraph_2'), _unref(campaignParams) )
    }, null, 8, _hoisted_2)
  ]))
}
}

})