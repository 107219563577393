import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "footer-wrapper is-hidden-print" }
const _hoisted_2 = { class: "container footer" }
const _hoisted_3 = { class: "footer-left" }
const _hoisted_4 = { class: "footer-logo" }
const _hoisted_5 = { href: "https://www.wikimedia.de/" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "footer-text" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { class: "footer-list" }
const _hoisted_11 = ["href", "aria-current"]

import { inject } from 'vue';
import { QUERY_STRING_INJECTION_KEY } from '@src/util/createCampaignQueryString';

interface Props {
	assetsPath: string;
	pageIdentifier: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  props: {
    assetsPath: {},
    pageIdentifier: {}
  },
  setup(__props: any) {



const campaignParams = inject<string>( QUERY_STRING_INJECTION_KEY, '' );
const footerMenu = [
	{ id: 'contact', url: `/contact/get-in-touch?${ campaignParams }` },
	{ id: 'imprint', url: `/page/Impressum?${ campaignParams }` },
	{ id: 'data_protection', url: `/page/Datenschutz?${ campaignParams }` },
	{ id: 'supporters_list', url: `/page/hall-of-fame?${ campaignParams }` },
	{ id: 'donor_comments', url: `/list-comments.html?${ campaignParams }` },
];


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.assetsPath + '/images/logo-vertical-wikimedia.svg',
              alt: "Wikimedia Deutschland"
            }, null, 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", {
            innerHTML: _ctx.$t( 'footer_text' )
          }, null, 8, _hoisted_8)
        ])
      ]),
      _createElementVNode("nav", {
        class: "footer-right",
        "aria-label": _ctx.$t( 'aria_footer_navigation_label' )
      }, [
        _createElementVNode("ul", _hoisted_10, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(footerMenu, (link, index) => {
            return _createElementVNode("li", { key: index }, [
              (_openBlock(), _createElementBlock("a", {
                href: link.url,
                key: link.id,
                "aria-current": link.id === _ctx.pageIdentifier ? 'page' : null
              }, _toDisplayString(_ctx.$t( 'footer_menu_' + link.id )), 9, _hoisted_11))
            ])
          }), 64))
        ])
      ], 8, _hoisted_9)
    ])
  ]))
}
}

})