import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "radio-field-tooltip" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "is-sr-only" }

import InfoIcon from '@src/components/shared/icons/InfoIcon.vue';

interface Props {
	textId?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioFieldTooltip',
  props: {
    textId: {}
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(InfoIcon),
    _createElementVNode("span", {
      id: _ctx.textId,
      class: "radio-field-tooltip-text"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})