import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "scroll-target" }
const _hoisted_2 = ["id"]

interface Props {
	targetId: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollTarget',
  props: {
    targetId: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { id: _ctx.targetId }, null, 8, _hoisted_2)
  ]))
}
}

})