import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

import { useFieldModel } from '@src/components/shared/form_fields/useFieldModel';
import { useSuggestedEmailProvider } from '@src/components/shared/form_fields/useSuggestedEmailProvider';
import { useMailHostList } from '@src/components/shared/useMailHostList';
import TextFormInput from '@src/components/shared/form_elements/TextFormInput.vue';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';
import { computed } from 'vue';

interface Props {
	modelValue: string;
	showError: boolean;
	inputId?: string;
	ariaDescribedby?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EmailField',
  props: {
    modelValue: {},
    showError: { type: Boolean },
    inputId: {},
    ariaDescribedby: { default: '' }
  },
  emits: [ 'update:modelValue', 'field-changed' ],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const mailHostList = useMailHostList();
const fieldModel = useFieldModel<string>( () => props.modelValue, props.modelValue );
const { suggestedProvider, onSuggestionClicked } = useSuggestedEmailProvider( fieldModel, mailHostList, emit );
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => props.ariaDescribedby ),
	`${( props.inputId ?? 'email' )}-error`,
	computed<boolean>( () => props.showError )
);

const onUpdateModel = ( newValue: string ): void => {
	emit( 'update:modelValue', newValue );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field form-field-email", { 'is-invalid': _ctx.showError }])
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId ?? 'email',
      class: "form-field-label"
    }, _toDisplayString(_ctx.$t( 'donation_form_email_label' )), 9, _hoisted_1),
    _createVNode(TextFormInput, {
      "input-type": "text",
      "input-id": _ctx.inputId ?? 'email',
      name: "email",
      placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_email_placeholder' ) } ),
      autocomplete: "email",
      modelValue: _unref(fieldModel),
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldModel) ? (fieldModel).value = $event : null)),
        onUpdateModel
      ],
      "has-error": _ctx.showError,
      "has-message": _unref(suggestedProvider) !== '',
      "aria-describedby": _unref(ariaDescribedby),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('field-changed', 'email')))
    }, null, 8, ["input-id", "placeholder", "modelValue", "has-error", "has-message", "aria-describedby"]),
    (_unref(suggestedProvider))
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "help is-clickable",
          role: "link",
          tabindex: "0",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(onSuggestionClicked)( _unref(suggestedProvider) ))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_unref(onSuggestionClicked)( _unref(suggestedProvider) )), ["enter","space"]))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_email_suggestion' )) + " ", 1),
          _createElementVNode("strong", null, _toDisplayString(_unref(suggestedProvider)), 1),
          _cache[4] || (_cache[4] = _createTextVNode("? "))
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "help is-danger error-email",
          id: `${(_ctx.inputId ?? 'email' )}-error`
        }, _toDisplayString(_ctx.$t( 'donation_form_email_error' )), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "message")
  ], 2))
}
}

})