import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "id", "value", "disabled", "required", "readonly", "aria-readonly", "aria-describedby", "aria-invalid", "aria-disabled", "autofocus"]
const _hoisted_2 = ["for"]

import { useInputModel } from '@src/components/shared/form_elements/useInputModel';

interface Props {
	modelValue: string | number | boolean | null;
	nativeValue: string | number | boolean;
	name: string;
	id: string;
	inputClass?: string;
	labelClass?: string;
	disabled?: boolean;
	required?: boolean;
	ariaDescribedby?: string;
	ariaInvalid?: boolean;
	autofocus?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioFormInput',
  props: {
    modelValue: { type: [String, Number, Boolean, null] },
    nativeValue: { type: [String, Number, Boolean] },
    name: {},
    id: {},
    inputClass: {},
    labelClass: {},
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    ariaDescribedby: { default: '' },
    ariaInvalid: { type: Boolean, default: false },
    autofocus: { type: Boolean }
  },
  emits: [ 'update:modelValue', 'blur' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const inputModel = useInputModel<string | number | boolean | null>( () => props.modelValue, props.modelValue, emit );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["radio radio-form-input", { 'is-disabled': _ctx.disabled, 'is-active': _unref(inputModel) === _ctx.nativeValue }])
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputModel) ? (inputModel).value = $event : null)),
      type: "radio",
      name: _ctx.name,
      id: _ctx.id,
      class: _normalizeClass(_ctx.inputClass),
      value: _ctx.nativeValue,
      disabled: _ctx.disabled,
      required: _ctx.required,
      readonly: _ctx.disabled,
      "aria-readonly": _ctx.disabled,
      "aria-describedby": _ctx.ariaDescribedby,
      "aria-invalid": _ctx.ariaInvalid,
      "aria-disabled": _ctx.disabled,
      autofocus: _ctx.autofocus,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit( 'blur' )))
    }, null, 42, _hoisted_1), [
      [_vModelRadio, _unref(inputModel)]
    ]),
    _createElementVNode("label", {
      class: _normalizeClass(["control-label", _ctx.labelClass]),
      for: _ctx.id,
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit( 'blur' )))
    }, [
      _renderSlot(_ctx.$slots, "label"),
      _renderSlot(_ctx.$slots, "help-text"),
      _renderSlot(_ctx.$slots, "tooltip")
    ], 42, _hoisted_2)
  ], 2))
}
}

})