import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, unref as _unref, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-field-label"
}
const _hoisted_2 = { class: "control form-field-radio-container" }
const _hoisted_3 = ["id"]

import { CheckboxFormOption } from '@src/components/shared/form_fields/FormOptions';
import RadioFormInput from '@src/components/shared/form_elements/RadioFormInput.vue';
import { useFieldModel } from '@src/components/shared/form_fields/useFieldModel';
import { computed } from 'vue';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';

interface Props {
	label?: String;
	name: string;
	modelValue: string | number | boolean | null;
	options: CheckboxFormOption[];
	disabled?: Array<string | number | boolean>;
	required?: boolean;
	showError?: boolean;
	errorMessage?: String;
	alignment: 'row' | 'column' | 'twocolumnsperrow';
	autofocus?: boolean;
	ariaDescribedby?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioField',
  props: {
    label: {},
    name: {},
    modelValue: { type: [String, Number, Boolean, null] },
    options: {},
    disabled: { default: () => [] },
    required: { type: Boolean, default: false },
    showError: { type: Boolean, default: false },
    errorMessage: {},
    alignment: {},
    autofocus: { type: Boolean },
    ariaDescribedby: { default: '' }
  },
  emits: [ 'update:modelValue', 'field-changed' ],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => props.ariaDescribedby ),
	`${props.name}-error-message`,
	computed<boolean>( () => props.showError )
);
const fieldModel = useFieldModel<string | number | boolean | null>( () => props.modelValue, props.modelValue );

const onFieldChange = ( newValue: string | number | boolean | null ): void => {
	emit( 'update:modelValue', newValue );
	emit( 'field-changed', props.name );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass(["form-field form-field-radio", [ _ctx.alignment + '-alignment', { 'is-invalid': _ctx.showError } ]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("legend", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "intro-message"),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createBlock(RadioFormInput, {
          key: index,
          "input-type": "radio",
          id: option.id,
          name: _ctx.name,
          disabled: _ctx.disabled.includes( option.value ),
          required: _ctx.required,
          "native-value": option.value,
          "aria-describedby": _unref(ariaDescribedby),
          "aria-invalid": _ctx.showError,
          modelValue: _unref(fieldModel),
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldModel) ? (fieldModel).value = $event : null)),
            onFieldChange
          ],
          autofocus: _ctx.autofocus
        }, {
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.label), 1)
          ]),
          "help-text": _withCtx(() => [
            _renderSlot(_ctx.$slots, `message-${option.value}`)
          ]),
          tooltip: _withCtx(() => [
            _renderSlot(_ctx.$slots, `tooltip-${option.value}`)
          ]),
          _: 2
        }, 1032, ["id", "name", "disabled", "required", "native-value", "aria-describedby", "aria-invalid", "modelValue", "autofocus"]))
      }), 128))
    ]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "help is-danger",
          id: `${_ctx.name}-error-message`
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}
}

})