import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-content" }
const _hoisted_2 = {
  key: 0,
  class: "app-content-sidebar"
}

interface Props {
	isFullWidth: boolean;
	usesContentCards: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppContent',
  props: {
    isFullWidth: { type: Boolean },
    usesContentCards: { type: Boolean }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["app-content-main", { 'uses-cards': _ctx.usesContentCards }])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2),
    (!_ctx.isFullWidth)
      ? (_openBlock(), _createElementBlock("aside", _hoisted_2, [
          _renderSlot(_ctx.$slots, "sidebar")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})