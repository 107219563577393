import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  name: "laika-donation-personal-data-person",
  id: "laika-donation-personal-data-person",
  action: "/donation/add",
  method: "post"
}
const _hoisted_2 = { class: "form-field form-field-donation-receipt" }
const _hoisted_3 = {
  name: "laika-donation-personal-data-company",
  id: "laika-donation-personal-data-company",
  action: "/donation/add",
  method: "post"
}
const _hoisted_4 = { class: "form-field form-field-donation-receipt" }
const _hoisted_5 = {
  name: "laika-donation-personal-data-email",
  id: "laika-donation-personal-data-email",
  action: "/donation/add",
  method: "post"
}

import { computed, onBeforeMount, ref, toRefs } from 'vue';
import PostalAddressFields from '@src/components/pages/donation_form/PostalAddressFields.vue';
import AutofillHandler from '@src/components/shared/AutofillHandler.vue';
import CheckboxSingleFormInput from '@src/components/shared/form_elements/CheckboxSingleFormInput.vue';
import EmailField from '@src/components/shared/form_fields/EmailField.vue';
import MailingListField from '@src/components/shared/form_fields/MailingListField.vue';
import NameFields from '@src/components/shared/NameFields.vue';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { Country } from '@src/view_models/Country';
import { AddressValidation } from '@src/view_models/Validation';
import { useAddressFunctions } from './AddressFunctions';
import { Salutation } from '@src/view_models/Salutation';
import { TrackingData } from '@src/view_models/TrackingData';
import { CampaignValues } from '@src/view_models/CampaignValues';
import { AddressTypeIds } from '@src/components/pages/donation_form/AddressTypeIds';
import { Validity } from '@src/view_models/Validity';
import ValueEqualsPlaceholderWarning from '@src/components/shared/ValueEqualsPlaceholderWarning.vue';
import { useMailingListModel } from '@src/components/shared/form_fields/useMailingListModel';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import { useStore } from 'vuex';
import { useReceiptModel } from '@src/components/shared/composables/useReceiptModel';

interface Props {
	countries: Country[];
	addressValidationPatterns: AddressValidation;
	addressType: AddressTypeModel;
	salutations: Salutation[];
	trackingData: TrackingData;
	campaignValues: CampaignValues;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressForms',
  props: {
    countries: {},
    addressValidationPatterns: {},
    addressType: { default: AddressTypeModel.PERSON },
    salutations: {},
    trackingData: {},
    campaignValues: {}
  },
  setup(__props: any) {

const props = __props;

const { addressType, addressValidationPatterns } = toRefs( props );
const store = useStore();
const {
	formData,
	fieldErrors,
	initializeDataFromStore,
	onFieldChange,
	onAutofill,
} = useAddressFunctions( { addressValidationPatterns: addressValidationPatterns.value }, store );

const mailingList = useMailingListModel( store );

const { receiptNeeded } = useReceiptModel( store );

const addressTypeId = computed( () => {
	if ( addressType.value === AddressTypeModel.UNSET ) {
		return AddressTypeIds.get( AddressTypeModel.PERSON );
	}
	return AddressTypeIds.has( addressType.value ) ? AddressTypeIds.get( addressType.value ) : '';
} );

const countryWasRestored = ref<boolean>( false );

onBeforeMount( () => {
	countryWasRestored.value = store.state.address.validity.country === Validity.RESTORED;
	initializeDataFromStore();
} );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`address-section address-type-${ addressTypeId.value }`)
  }, [
    _createElementVNode("form", _hoisted_1, [
      _createVNode(AutofillHandler, { onAutofill: _unref(onAutofill) }, {
        default: _withCtx(() => [
          _createVNode(NameFields, {
            "show-error": _unref(fieldErrors),
            "form-data": _unref(formData),
            "address-type": _unref(AddressTypeModel).PERSON,
            salutations: _ctx.salutations,
            "field-id-namespace": "person",
            onFieldChanged: _unref(onFieldChange)
          }, null, 8, ["show-error", "form-data", "address-type", "salutations", "onFieldChanged"]),
          _createVNode(PostalAddressFields, {
            "show-error": _unref(fieldErrors),
            "form-data": _unref(formData),
            countries: _ctx.countries,
            "post-code-validation": _unref(addressValidationPatterns).postcode,
            "country-was-restored": countryWasRestored.value,
            "field-id-namespace": "person",
            onFieldChanged: _unref(onFieldChange)
          }, null, 8, ["show-error", "form-data", "countries", "post-code-validation", "country-was-restored", "onFieldChanged"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(CheckboxSingleFormInput, {
              "input-id": "receipt-option-person",
              name: "receipt-option",
              modelValue: _unref(receiptNeeded),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(receiptNeeded) ? (receiptNeeded).value = $event : null))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t( 'receipt_needed_donation_page' )), 1)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createVNode(ScrollTarget, { "target-id": "person-email-scroll-target" }),
          _createVNode(EmailField, {
            "show-error": _unref(fieldErrors).email,
            modelValue: _unref(formData).email.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formData).email.value) = $event)),
            "input-id": "person-email",
            onFieldChanged: _unref(onFieldChange)
          }, {
            message: _withCtx(() => [
              _createVNode(ValueEqualsPlaceholderWarning, {
                value: _unref(formData).email.value,
                placeholder: _ctx.$t( 'donation_form_email_placeholder' ),
                warning: "donation_form_email_placeholder_warning"
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["show-error", "modelValue", "onFieldChanged"]),
          _createVNode(MailingListField, {
            modelValue: _unref(mailingList),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(mailingList) ? (mailingList).value = $event : null)),
            "input-id": "person-newsletter"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["onAutofill"])
    ]),
    _createElementVNode("form", _hoisted_3, [
      _createVNode(AutofillHandler, { onAutofill: _unref(onAutofill) }, {
        default: _withCtx(() => [
          _createVNode(NameFields, {
            "show-error": _unref(fieldErrors),
            "form-data": _unref(formData),
            "address-type": _unref(AddressTypeModel).COMPANY,
            salutations: _ctx.salutations,
            "field-id-namespace": "company",
            onFieldChanged: _unref(onFieldChange)
          }, null, 8, ["show-error", "form-data", "address-type", "salutations", "onFieldChanged"]),
          _createVNode(PostalAddressFields, {
            "show-error": _unref(fieldErrors),
            "form-data": _unref(formData),
            countries: _ctx.countries,
            "post-code-validation": _unref(addressValidationPatterns).postcode,
            "country-was-restored": countryWasRestored.value,
            "field-id-namespace": "company",
            onFieldChanged: _unref(onFieldChange)
          }, null, 8, ["show-error", "form-data", "countries", "post-code-validation", "country-was-restored", "onFieldChanged"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(CheckboxSingleFormInput, {
              "input-id": "receipt-option-company",
              name: "receipt-option",
              modelValue: _unref(receiptNeeded),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(receiptNeeded) ? (receiptNeeded).value = $event : null))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t( 'receipt_needed_donation_page' )), 1)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createVNode(ScrollTarget, { "target-id": "company-email-scroll-target" }),
          _createVNode(EmailField, {
            "show-error": _unref(fieldErrors).email,
            modelValue: _unref(formData).email.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formData).email.value) = $event)),
            "input-id": "company-email",
            onFieldChanged: _unref(onFieldChange)
          }, {
            message: _withCtx(() => [
              _createVNode(ValueEqualsPlaceholderWarning, {
                value: _unref(formData).email.value,
                placeholder: _ctx.$t( 'donation_form_email_placeholder' ),
                warning: "donation_form_email_placeholder_warning"
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["show-error", "modelValue", "onFieldChanged"]),
          _createVNode(MailingListField, {
            modelValue: _unref(mailingList),
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(mailingList) ? (mailingList).value = $event : null)),
            "input-id": "company-newsletter"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["onAutofill"])
    ]),
    _createElementVNode("form", _hoisted_5, [
      _createVNode(AutofillHandler, { onAutofill: _unref(onAutofill) }, {
        default: _withCtx(() => [
          _createVNode(NameFields, {
            "show-error": _unref(fieldErrors),
            "form-data": _unref(formData),
            "address-type": _unref(AddressTypeModel).PERSON,
            salutations: _ctx.salutations,
            "field-id-namespace": "email",
            onFieldChanged: _unref(onFieldChange)
          }, null, 8, ["show-error", "form-data", "address-type", "salutations", "onFieldChanged"]),
          _createVNode(ScrollTarget, { "target-id": "email-email-scroll-target" }),
          _createVNode(EmailField, {
            "show-error": _unref(fieldErrors).email,
            modelValue: _unref(formData).email.value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formData).email.value) = $event)),
            "input-id": "email-email",
            onFieldChanged: _unref(onFieldChange)
          }, {
            message: _withCtx(() => [
              _createVNode(ValueEqualsPlaceholderWarning, {
                value: _unref(formData).email.value,
                placeholder: _ctx.$t( 'donation_form_email_placeholder' ),
                warning: "donation_form_email_placeholder_warning"
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["show-error", "modelValue", "onFieldChanged"]),
          _createVNode(MailingListField, {
            modelValue: _unref(mailingList),
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(mailingList) ? (mailingList).value = $event : null)),
            "input-id": "email-newsletter"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["onAutofill"])
    ])
  ], 2))
}
}

})