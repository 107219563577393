import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["href", "aria-current"]

interface Props {
	showMobileNavbar: boolean;
	pageIdentifier: string;
	headerMenu: { ids: string[]; url: string; localeId: string }[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationItems',
  props: {
    showMobileNavbar: { type: Boolean },
    pageIdentifier: {},
    headerMenu: {}
  },
  emits: [ 'click', 'blur', 'esc' ],
  setup(__props: any) {






return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "main-navigation-items",
    class: _normalizeClass(["navigation-items", { 'active': _ctx.showMobileNavbar }]),
    "aria-label": _ctx.$t( 'aria_main_navigation_label' )
  }, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerMenu, (link, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("a", {
            href: link.url,
            class: _normalizeClass(["navigation-item", { 'active': link.ids.includes( _ctx.pageIdentifier ) }]),
            "aria-current": link.ids.includes( _ctx.pageIdentifier ) ? 'page' : null,
            onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit( 'blur' ))),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit( 'esc' )), ["esc"])),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit( 'click' )))
          }, _toDisplayString(_ctx.$t( 'header_menu_item_' + link.localeId )), 43, _hoisted_2)
        ]))
      }), 128))
    ])
  ], 10, _hoisted_1))
}
}

})