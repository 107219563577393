import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import FormButton from '@src/components/shared/form_elements/FormButton.vue';

interface Props {
	isLoading: boolean;
	paymentType: 'PPL' | 'UEB' | 'MCP' | 'SUB';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentTextFormButton',
  props: {
    isLoading: { type: Boolean },
    paymentType: {}
  },
  setup(__props: any) {

const props = __props;

const { t } = useI18n();

const dynamicButtonText = computed( () => {
	switch ( props.paymentType ) {
		case 'PPL':
			return t( 'donation_form_finalize_paypal' );
		case 'UEB':
			return t( 'donation_form_finalize_bank_transfer' );
		case 'MCP':
			return t( 'donation_form_finalize_credit_card' );
		case 'SUB':
			return t( 'donation_form_finalize_sofort' );
		default:
			return t( 'donation_form_finalize' );
	}
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FormButton, {
    "is-loading": _ctx.isLoading,
    "button-type": "submit"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(dynamicButtonText.value), 1)
    ]),
    _: 1
  }, 8, ["is-loading"]))
}
}

})