import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-field-label"
}
const _hoisted_2 = { class: "form-field-help-text" }
const _hoisted_3 = {
  key: 1,
  class: "form-field-help-text"
}
const _hoisted_4 = { class: "control form-field-amount-radio-container" }
const _hoisted_5 = {
  class: "form-field-amount-help-text",
  for: "amount-custom"
}
const _hoisted_6 = { class: "radio radio-form-input" }
const _hoisted_7 = ["checked"]
const _hoisted_8 = {
  key: 2,
  class: "help is-danger",
  id: "amount-error"
}

import { computed, ref, watch } from 'vue';
import RadioFormInput from '@src/components/shared/form_elements/RadioFormInput.vue';
import TextFormInput from '@src/components/shared/form_elements/TextFormInput.vue';
import { useI18n } from 'vue-i18n';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';

interface Props {
	modelValue: string;
	paymentAmounts: number[];
	minimumAmount?: number;
	showError?: boolean;
	label?: String;
	errorMessage?: String;
	minimumAmountMessage?: string;
	ariaDescribedby?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AmountField',
  props: {
    modelValue: {},
    paymentAmounts: {},
    minimumAmount: { default: 0 },
    showError: { type: Boolean, default: false },
    label: {},
    errorMessage: {},
    minimumAmountMessage: { default: '' },
    ariaDescribedby: { default: '' }
  },
  emits: [ 'update:modelValue', 'field-changed' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const { n } = useI18n();
const amount = ref<number>( Number( props.modelValue ) );
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => props.ariaDescribedby ),
	'amount-error',
	computed<boolean>( () => props.showError )
);
const isCustomAmount = computed<boolean>( () => amount.value > 0 && props.paymentAmounts.indexOf( amount.value ) === -1 );

const getFormattedCustomAmount = (): string => {
	if ( !isCustomAmount.value ) {
		return '';
	}
	return n( amount.value / 100, 'decimal' );
};

const customAmount = ref<string>( getFormattedCustomAmount() );

const updateAmountFromRadio = ( newAmount: number ) => {
	customAmount.value = '';
	emit( 'update:modelValue', String( newAmount ) );
	emit( 'field-changed' );
};

const updateAmountFromCustom = ( newAmount: string ) => {
	newAmount = newAmount.trim();
	if ( newAmount === '' ) {
		amount.value = 0;
		return;
	}

	const numericalAmount = Number( newAmount.replace( /,/, '.' ) );
	if ( isNaN( numericalAmount ) ) {
		amount.value = 0;
		return;
	}

	amount.value = Math.trunc( numericalAmount * 100 );
};

const setCustomAmount = ( e: Event ): void => {
	e.preventDefault();

	customAmount.value = getFormattedCustomAmount();
	if ( amount.value > 0 ) {
		emit( 'update:modelValue', String( amount.value ) );
	} else {
		emit( 'update:modelValue', '' );
		emit( 'field-changed' );
	}
};

const resetErrorInput = (): void => {
	if ( props.showError ) {
		customAmount.value = '';
	}
};

watch( () => props.modelValue, ( newValue: string ) => {
	amount.value = Number( newValue );
	customAmount.value = getFormattedCustomAmount();
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass(["form-field form-field-amount", [ `locale-${ _ctx.$i18n.locale }`, { 'is-invalid': _ctx.showError } ]])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("legend", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t( 'donation_form_payment_amount_help_text' )), 1),
    (_ctx.minimumAmountMessage!='')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.minimumAmountMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentAmounts, (paymentAmount, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "form-field-amount-radio",
          key: index
        }, [
          _createVNode(RadioFormInput, {
            "native-value": paymentAmount,
            name: "amount",
            modelValue: amount.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((amount).value = $event)),
              updateAmountFromRadio
            ],
            class: _normalizeClass({ 'inactive': paymentAmount < _ctx.minimumAmount }),
            disabled: paymentAmount < _ctx.minimumAmount,
            id: `amount-${paymentAmount}`,
            "aria-invalid": _ctx.showError,
            "aria-describedby": _unref(ariaDescribedby)
          }, {
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$n( paymentAmount / 100, 'euros' )), 1)
            ]),
            _: 2
          }, 1032, ["native-value", "modelValue", "class", "disabled", "id", "aria-invalid", "aria-describedby"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["form-field-amount-custom", { active: isCustomAmount.value }])
    }, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('donation_form_payment_amount_label')), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["form-field-amount-custom-euro-symbol", { active: isCustomAmount.value }])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("input", {
            type: "radio",
            class: "form-field-amount-custom-radio",
            checked: isCustomAmount.value,
            "aria-hidden": "true",
            tabindex: "-1"
          }, null, 8, _hoisted_7)
        ]),
        _createVNode(TextFormInput, {
          modelValue: customAmount.value,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((customAmount).value = $event)),
            updateAmountFromCustom
          ],
          "input-type": "text",
          "input-id": "amount-custom",
          "has-message": false,
          name: "custom-amount",
          placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_custom_placeholder' ) } ),
          onKeydown: _withKeys(setCustomAmount, ["enter"]),
          onBlur: setCustomAmount,
          onFocus: _withModifiers(resetErrorInput, ["prevent"]),
          "aria-invalid": _ctx.showError,
          "aria-describedby": _unref(ariaDescribedby)
        }, null, 8, ["modelValue", "placeholder", "aria-invalid", "aria-describedby"])
      ], 2)
    ], 2),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "info-message")
  ], 2))
}
}

})