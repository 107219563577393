import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-summary-content" }

import { AddressTypeModel, addressTypeName } from '@src/view_models/AddressTypeModel';
import PaymentSummaryAnonymous from '@src/components/pages/donation_form/PaymentSummaryContent/PaymentSummaryAnonymous.vue';
import PaymentSummaryCompany from '@src/components/pages/donation_form/PaymentSummaryContent/PaymentSummaryCompany.vue';
import PaymentSummaryCompanyWithContact from '@src/components/pages/donation_form/PaymentSummaryContent/PaymentSummaryCompanyWithContact.vue';
import PaymentSummaryEmail from '@src/components/pages/donation_form/PaymentSummaryContent/PaymentSummaryEmail.vue';
import PaymentSummaryPrivate from '@src/components/pages/donation_form/PaymentSummaryContent/PaymentSummaryPrivate.vue';
import { Country } from '@src/view_models/Country';
import { Salutation } from '@src/view_models/Salutation';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

interface Props {
	address: Record<string, string>;
	addressType: String;
	payment: { interval: any; amount: number; paymentType: any };
	countries: Array<Country>;
	languageItem: String;
	salutations: Array<Salutation>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DonationSummary',
  props: {
    address: {},
    addressType: {},
    payment: {},
    countries: {},
    languageItem: {},
    salutations: {}
  },
  setup(__props: any) {

const { t, n } = useI18n();
const props = __props;

const addressTypeComponents = [
	{ key: addressTypeName( AddressTypeModel.ANON ), component: PaymentSummaryAnonymous },
	{ key: addressTypeName( AddressTypeModel.COMPANY ), component: PaymentSummaryCompany },
	{ key: addressTypeName( AddressTypeModel.COMPANY_WITH_CONTACT ), component: PaymentSummaryCompanyWithContact },
	{ key: addressTypeName( AddressTypeModel.EMAIL ), component: PaymentSummaryEmail },
	{ key: addressTypeName( AddressTypeModel.PERSON ), component: PaymentSummaryPrivate },
	{ key: addressTypeName( AddressTypeModel.UNSET ), component: PaymentSummaryAnonymous },
];

// TODO: Extract this into a composable
const addressTypeComponent = computed( () => addressTypeComponents.find( x => x.key === props.addressType ).component );
const paymentType = computed( () => t( props.payment.paymentType ) );
const interval = computed( () => t( 'donation_form_payment_interval_' + props.payment.interval ) );
const formattedAmount = computed( () => n( props.payment.amount, { key: 'currency', currencyDisplay: 'name' } ) );
const country = computed( () => {
	const countryObject = props.countries.find( c => ( c.countryCode === props.address.countryCode || c.countryCode === props.address.country ) );
	return countryObject ? countryObject.countryFullName : '';
} );
const salutation = computed( () => props.address.salutation ? props.salutations.find( s => s.value === props.address.salutation )?.display : '' );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(addressTypeComponent.value), {
      address: _ctx.address,
      interval: interval.value,
      "formatted-amount": formattedAmount.value,
      paymentType: paymentType.value,
      country: country.value,
      "language-item": _ctx.languageItem,
      salutation: salutation.value
    }, null, 8, ["address", "interval", "formatted-amount", "paymentType", "country", "language-item", "salutation"]))
  ]))
}
}

})