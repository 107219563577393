import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "field-info-message" }

import { useFieldModel } from '@src/components/shared/form_fields/useFieldModel';
import TextFormInput from '@src/components/shared/form_elements/TextFormInput.vue';
import { computed } from 'vue';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';

interface Props {
	inputType?: 'text' | 'textarea';
	label: String;
	labelHelpText?: String;
	helpText?: String;
	name: string;
	inputId: string;
	placeholder: string;
	modelValue: string | number;
	errorMessage: String;
	showError: boolean;
	disabled?: boolean;
	required?: boolean;
	autocomplete?: string;
	autofocus?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextField',
  props: {
    inputType: { default: 'text' },
    label: {},
    labelHelpText: {},
    helpText: {},
    name: {},
    inputId: {},
    placeholder: {},
    modelValue: {},
    errorMessage: {},
    showError: { type: Boolean },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    autocomplete: { default: 'on' },
    autofocus: { type: Boolean }
  },
  emits: [ 'update:modelValue', 'field-changed' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const fieldModel = useFieldModel<string | number>( () => props.modelValue, props.modelValue );
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => ( props.helpText ? `${ props.inputId }-help-text` : '' ) ),
	`${props.inputId}-error`,
	computed<boolean>( () => props.showError )
);

const onUpdateModel = ( newValue: string | number ): void => {
	emit( 'update:modelValue', newValue );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field", [ `form-field-${_ctx.inputType}`, { 'is-invalid': _ctx.showError } ]])
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: "form-field-label"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.labelHelpText)
        ? (_openBlock(), _createElementBlock("em", _hoisted_2, _toDisplayString(_ctx.labelHelpText), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "form-field-help-text",
          id: `${_ctx.inputId}-help-text`
        }, _toDisplayString(_ctx.helpText), 9, _hoisted_3))
      : _createCommentVNode("", true),
    _createVNode(TextFormInput, {
      name: _ctx.name,
      "input-type": _ctx.inputType,
      modelValue: _unref(fieldModel),
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => (_isRef(fieldModel) ? (fieldModel).value = $event : null)),
        onUpdateModel
      ],
      "input-id": _ctx.inputId,
      "has-error": _ctx.showError,
      "has-message": false,
      placeholder: _ctx.placeholder,
      autocomplete: _ctx.autocomplete,
      disabled: _ctx.disabled,
      required: _ctx.required,
      autofocus: _ctx.autofocus,
      "aria-describedby": _unref(ariaDescribedby),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('field-changed', _ctx.name )))
    }, null, 8, ["name", "input-type", "modelValue", "input-id", "has-error", "placeholder", "autocomplete", "disabled", "required", "autofocus", "aria-describedby"]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "help is-danger",
          id: `${_ctx.inputId}-error`
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_5, [
      _renderSlot(_ctx.$slots, "message")
    ])
  ], 2))
}
}

})