import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { TrackingData } from '@src/view_models/TrackingData';
import { Country } from '@src/view_models/Country';
import { AddressValidation } from '@src/view_models/Validation';
import { Salutation } from '@src/view_models/Salutation';
import { CampaignValues } from '@src/view_models/CampaignValues';
import StandardDonationForm from '@src/components/pages/donation_form/SubPages/DonationForm.vue';
import ReceiptDonationForm from '@src/components/pages/donation_form/SubPages/DonationFormReceipt.vue';
import ChoiceDonationForm from '@src/components/pages/donation_form/SubPages/DonationFormAnonymousChoice.vue';

interface Props {
	assetsPath: string;
	validateAddressUrl: string;
	validateEmailUrl: string;
	validateBankDataUrl: string;
	validateLegacyBankDataUrl: string;
	paymentAmounts: number[];
	paymentIntervals: number[];
	paymentTypes: string[];
	countries: Country[];
	salutations: Salutation[];
	trackingData: TrackingData;
	campaignValues: CampaignValues;
	addressValidationPatterns: AddressValidation;
}

export default /*@__PURE__*/_defineComponent({
  ...{
	name: 'DonationForm',
},
  __name: 'DonationForm',
  props: {
    assetsPath: {},
    validateAddressUrl: {},
    validateEmailUrl: {},
    validateBankDataUrl: {},
    validateLegacyBankDataUrl: {},
    paymentAmounts: {},
    paymentIntervals: {},
    paymentTypes: {},
    countries: {},
    salutations: {},
    trackingData: {},
    campaignValues: {},
    addressValidationPatterns: {}
  },
  setup(__props: any) {



const props = __props;


return (_ctx: any,_cache: any) => {
  const _component_FeatureToggle = _resolveComponent("FeatureToggle")!

  return (_openBlock(), _createBlock(_component_FeatureToggle, { "default-template": "campaigns.address_pages.legacy" }, {
    "campaigns.address_pages.legacy": _withCtx(() => [
      _createVNode(_component_FeatureToggle, { "default-template": "campaigns.address_type_choice.default" }, {
        "campaigns.address_type_choice.default": _withCtx(() => [
          _createVNode(StandardDonationForm, _normalizeProps(_guardReactiveProps(props)), null, 16)
        ]),
        "campaigns.address_type_choice.choice": _withCtx(() => [
          _createVNode(ChoiceDonationForm, _normalizeProps(_guardReactiveProps(props)), null, 16)
        ]),
        _: 1
      })
    ]),
    "campaigns.address_pages.test_02": _withCtx(() => [
      _createVNode(ReceiptDonationForm, _normalizeProps(_guardReactiveProps(props)), null, 16)
    ]),
    _: 1
  }))
}
}

})