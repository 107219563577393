import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "form-section" }

interface Props {
	title?: String;
	titleMargin?: 'x-small' | 'small' | 'medium';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSection',
  props: {
    title: {},
    titleMargin: { default: 'small' }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          class: _normalizeClass(["form-section-title", `form-section-title-${_ctx.titleMargin}`])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})