import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, isRef as _isRef, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select" }
const _hoisted_2 = ["name", "id", "disabled", "required", "aria-invalid", "aria-describedby"]

import { useInputModel } from '@src/components/shared/form_elements/useInputModel';

interface Props {
	modelValue: string | number;
	name: string;
	selectId: string;
	hasError?: boolean;
	disabled?: boolean;
	required?: boolean;
	ariaDescribedby?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectFormInput',
  props: {
    modelValue: {},
    name: {},
    selectId: {},
    hasError: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    ariaDescribedby: {}
  },
  emits: [ 'update:modelValue', 'focus', 'blur' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const inputModel = useInputModel<string | number>( () => props.modelValue, props.modelValue, emit );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control select-form-input", { 'is-disabled': _ctx.disabled }])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputModel) ? (inputModel).value = $event : null)),
        name: _ctx.name,
        id: _ctx.selectId,
        disabled: _ctx.disabled,
        required: _ctx.required,
        class: _normalizeClass({ 'is-danger': _ctx.hasError }),
        "aria-invalid": _ctx.hasError,
        "aria-describedby": _ctx.ariaDescribedby
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_2), [
        [_vModelSelect, _unref(inputModel)]
      ])
    ])
  ], 2))
}
}

})