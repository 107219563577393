import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "donation-page-form-section-personal-data",
  class: "donation-page-form-section",
  "aria-live": "assertive",
  "aria-labelledby": "donation-form-subheading donation-form-tagline"
}
const _hoisted_2 = {
  id: "donation-form-subheading",
  class: "form-subtitle"
}
const _hoisted_3 = { id: "donation-form-tagline" }

import { toRef } from 'vue';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import AddressTypeNoAnon from '@src/components/pages/donation_form/AddressTypeNoAnon.vue';
import AddressForms from '@src/components/pages/donation_form/AddressForms.vue';
import RadioField from '@src/components/shared/form_fields/RadioField.vue';
import { CampaignValues } from '@src/view_models/CampaignValues';
import { Country } from '@src/view_models/Country';
import { Salutation } from '@src/view_models/Salutation';
import { TrackingData } from '@src/view_models/TrackingData';
import { AddressValidation } from '@src/view_models/Validation';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';
import { AddressOptOutModel } from '@src/components/pages/donation_form/AddressOptOut/useAddressOptOut';
import RadioFieldHelpText from '@src/components/shared/form_elements/RadioFieldTooltip.vue';

interface Props {
	countries: Country[];
	salutations: Salutation[];
	trackingData: TrackingData;
	campaignValues: CampaignValues;
	addressValidationPatterns: AddressValidation;
	isDirectDebitPayment: boolean;
	disabledAddressTypes: AddressTypeModel[];
	addressType: AddressTypeModel;
	addressTypeIsInvalid: boolean;
	addressOptOut: AddressOptOutModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalDataSectionAnonymousChoice',
  props: {
    countries: {},
    salutations: {},
    trackingData: {},
    campaignValues: {},
    addressValidationPatterns: {},
    isDirectDebitPayment: { type: Boolean },
    disabledAddressTypes: {},
    addressType: {},
    addressTypeIsInvalid: { type: Boolean },
    addressOptOut: {}
  },
  emits: [ 'set-address-type' ],
  setup(__props: any) {

const props = __props;


const addressOptOut = toRef( props.addressOptOut );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t( 'donation_form_address_subheading' )), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t( 'donation_form_section_address_tagline' )), 1),
    _createElementVNode("form", {
      id: "address-type-option",
      onSubmit: _cache[1] || (_cache[1] = evt => evt.preventDefault())
    }, [
      _createVNode(ScrollTarget, { "target-id": "address-opt-out-scroll-target" }),
      _createVNode(RadioField, {
        modelValue: addressOptOut.value.addressOptIn,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addressOptOut.value.addressOptIn) = $event)),
        name: "address-opt-out",
        options: [
						{ value: true, label: _ctx.$t( 'yes' ), id: 'addressOptOut-0' },
						{ value: false, label: _ctx.$t( 'no' ), id: 'addressOptOut-1' },
					],
        label: _ctx.$t( 'donation_form_section_address_opt_out' ),
        disabled: _ctx.isDirectDebitPayment ? [ false ] : [],
        alignment: "row",
        "aria-describedby": "donation-address-opt-out-help-text"
      }, {
        "intro-message": _withCtx(() => _cache[4] || (_cache[4] = [])),
        "tooltip-false": _withCtx(() => [
          (_ctx.isDirectDebitPayment)
            ? (_openBlock(), _createBlock(RadioFieldHelpText, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t( 'donation_form_address_choice_direct_debit_disclaimer' )), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "options", "label", "disabled"])
    ], 32),
    (addressOptOut.value.addressOptIn === true)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          id: "address-type-selection",
          onSubmit: _cache[3] || (_cache[3] = evt => evt.preventDefault())
        }, [
          _createVNode(ScrollTarget, { "target-id": "address-type-scroll-target" }),
          _createVNode(AddressTypeNoAnon, {
            onAddressType: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit( 'set-address-type', $event ))),
            disabledAddressTypes: [],
            "is-direct-debit": _ctx.isDirectDebitPayment,
            "initial-address-type": _ctx.addressType,
            "address-type-is-invalid": _ctx.addressTypeIsInvalid
          }, null, 8, ["is-direct-debit", "initial-address-type", "address-type-is-invalid"])
        ], 32))
      : _createCommentVNode("", true),
    (addressOptOut.value.addressOptIn === true)
      ? (_openBlock(), _createBlock(AddressForms, {
          key: 1,
          countries: _ctx.countries,
          salutations: _ctx.salutations,
          "address-validation-patterns": _ctx.addressValidationPatterns,
          "address-type": _ctx.addressType,
          "tracking-data": _ctx.trackingData,
          "campaign-values": _ctx.campaignValues
        }, null, 8, ["countries", "salutations", "address-validation-patterns", "address-type", "tracking-data", "campaign-values"]))
      : _createCommentVNode("", true)
  ]))
}
}

})