import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-controls", "aria-expanded"]

interface Props {
	ariaControls?: string;
	ariaExpanded?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonLink',
  props: {
    ariaControls: {},
    ariaExpanded: { type: Boolean, default: false }
  },
  emits: [ 'click' ],
  setup(__props: any) {

/**
 * Use this when a button is required for accessibility (for example to open a modal) but it needs to be styled like a link.
 */






return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "button-link",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit( 'click' )), ["prevent"])),
    "aria-controls": _ctx.ariaControls,
    "aria-expanded": _ctx.ariaExpanded
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}
}

})