import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-cards" }
const _hoisted_2 = { class: "sidebar-card" }
const _hoisted_3 = { class: "sidebar-card-title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "sidebar-card" }
const _hoisted_6 = { class: "sidebar-card-title" }

import { inject } from 'vue';
import BankData from '@src/components/shared/BankData.vue';
import BankIcon from '@src/components/shared/icons/BankIcon.vue';
import InfoIcon from '@src/components/shared/icons/InfoIcon.vue';
import { appendCampaignQueryParams } from '@src/util/append_campaign_query_params';
import { QUERY_STRING_INJECTION_KEY } from '@src/util/createCampaignQueryString';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebar',
  setup(__props) {

const campaignParams = inject<string>( QUERY_STRING_INJECTION_KEY, '' );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createVNode(InfoIcon),
        _createTextVNode(_toDisplayString(_ctx.$t('sidebar_getintouch_headline')), 1)
      ]),
      _createElementVNode("p", {
        innerHTML: _unref(appendCampaignQueryParams)( _ctx.$t('sidebar_getintouch_mixed'), _unref(campaignParams) )
      }, null, 8, _hoisted_4),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h2", _hoisted_6, [
        _createVNode(BankIcon),
        _createTextVNode(_toDisplayString(_ctx.$t('bank_data_title')), 1)
      ]),
      _createVNode(BankData)
    ])
  ]))
}
}

})