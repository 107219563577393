import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "donation-page-form-section",
  id: "donation-page-form-section-personal-data-donation-receipt",
  "aria-labelledby": "donation-form-subheading donation-form-tagline"
}
const _hoisted_2 = {
  id: "donation-form-subheading",
  class: "form-subtitle"
}
const _hoisted_3 = { id: "donation-form-tagline" }
const _hoisted_4 = {
  id: "donation-form",
  action: "/donation/add",
  method: "post"
}

import { onBeforeMount, toRef } from 'vue';
import AddressFields from '@src/components/pages/donation_form/DonationReceipt/AddressFields.vue';
import AutofillHandler from '@src/components/shared/AutofillHandler.vue';
import EmailField from '@src/components/shared/form_fields/EmailField.vue';
import MailingListField from '@src/components/shared/form_fields/MailingListField.vue';
import NameFields from '@src/components/pages/donation_form/DonationReceipt/NameFields.vue';
import RadioField from '@src/components/shared/form_fields/RadioField.vue';
import ValueEqualsPlaceholderWarning from '@src/components/shared/ValueEqualsPlaceholderWarning.vue';
import { AddressValidation } from '@src/view_models/Validation';
import { CampaignValues } from '@src/view_models/CampaignValues';
import { Country } from '@src/view_models/Country';
import { Salutation } from '@src/view_models/Salutation';
import { TrackingData } from '@src/view_models/TrackingData';
import { useAddressFunctions } from '@src/components/pages/donation_form/AddressFunctions';
import { useAddressTypeFromReceiptSetter } from '@src/components/pages/donation_form/DonationReceipt/useAddressTypeFromReceiptSetter';
import { useMailingListModel } from '@src/components/shared/form_fields/useMailingListModel';
import { ReceiptModel } from '@src/components/pages/donation_form/DonationReceipt/useReceiptModel';
import { useStore } from 'vuex';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';

interface Props {
	countries: Country[];
	salutations: Salutation[];
	trackingData: TrackingData;
	campaignValues: CampaignValues;
	addressValidationPatterns: AddressValidation;
	isDirectDebitPayment: boolean;
	disabledAddressTypes: AddressTypeModel[];
	addressType: AddressTypeModel;
	receiptModel: ReceiptModel;
	addressTypeIsInvalid: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalDataSectionDonationReceipt',
  props: {
    countries: {},
    salutations: {},
    trackingData: {},
    campaignValues: {},
    addressValidationPatterns: {},
    isDirectDebitPayment: { type: Boolean },
    disabledAddressTypes: {},
    addressType: {},
    receiptModel: {},
    addressTypeIsInvalid: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const store = useStore();

const mailingList = useMailingListModel( store );
const receiptModel = toRef<ReceiptModel>( props.receiptModel );

const {
	formData,
	fieldErrors,
	initializeDataFromStore,
	onFieldChange,
	onAutofill,
} = useAddressFunctions( { addressValidationPatterns: props.addressValidationPatterns }, store );

useAddressTypeFromReceiptSetter( props.receiptModel.receiptNeeded, toRef( props.addressType ), store );

onBeforeMount( initializeDataFromStore );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t( 'donation_form_address_subheading' )), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t( 'donation_form_section_address_tagline' )), 1),
    _createElementVNode("form", _hoisted_4, [
      _createVNode(AutofillHandler, { onAutofill: _unref(onAutofill) }, {
        default: _withCtx(() => [
          _createVNode(NameFields, {
            "show-error": _unref(fieldErrors),
            "form-data": _unref(formData),
            salutations: _ctx.salutations,
            onFieldChanged: _unref(onFieldChange)
          }, null, 8, ["show-error", "form-data", "salutations", "onFieldChanged"]),
          _createVNode(ScrollTarget, { "target-id": "email-scroll-target" }),
          _createVNode(EmailField, {
            "show-error": _unref(fieldErrors).email,
            modelValue: _unref(formData).email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formData).email.value) = $event)),
            onFieldChanged: _unref(onFieldChange)
          }, {
            message: _withCtx(() => [
              _createVNode(ValueEqualsPlaceholderWarning, {
                value: _unref(formData).email.value,
                placeholder: _ctx.$t( 'donation_form_email_placeholder' ),
                warning: "donation_form_email_placeholder_warning"
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["show-error", "modelValue", "onFieldChanged"]),
          _createVNode(MailingListField, {
            modelValue: _unref(mailingList),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(mailingList) ? (mailingList).value = $event : null)),
            "input-id": "newsletter"
          }, null, 8, ["modelValue"]),
          _createVNode(ScrollTarget, { "target-id": "receipt-scroll-target" }),
          _createVNode(RadioField, {
            modelValue: receiptModel.value.receiptNeeded,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((receiptModel.value.receiptNeeded) = $event)),
            name: "donationReceipt",
            options: [
						{ value: true, label: _ctx.$t( 'yes' ), id: 'donationReceipt-0' },
						{ value: false, label: _ctx.$t( 'no' ), id: 'donationReceipt-1' },
					],
            label: _ctx.$t( 'donation_confirmation_cta_title_alt' ),
            "show-error": receiptModel.value.showReceiptOptionError,
            "error-message": _ctx.$t( 'C24_WMDE_Desktop_DE_01_receipt_error' ),
            alignment: "row",
            "aria-describedby": "donation-receipt-help-text"
          }, null, 8, ["modelValue", "options", "label", "show-error", "error-message"]),
          (receiptModel.value.receiptNeeded)
            ? (_openBlock(), _createBlock(AddressFields, {
                key: 0,
                "show-error": _unref(fieldErrors),
                "form-data": _unref(formData),
                countries: _ctx.countries,
                "post-code-validation": _ctx.addressValidationPatterns.postcode,
                onFieldChanged: _unref(onFieldChange)
              }, null, 8, ["show-error", "form-data", "countries", "post-code-validation", "onFieldChanged"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onAutofill"])
    ])
  ]))
}
}

})