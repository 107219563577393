import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "navigation-right" }

import { inject, ref } from 'vue';
import LocaleSelector from '@src/components/layout/LocaleSelector.vue';
import Logo from '@src/components/layout/Logo.vue';
import NavigationBurger from '@src/components/layout/NavigationBurger.vue';
import { QUERY_STRING_INJECTION_KEY } from '@src/util/createCampaignQueryString';
import { useDisplaySwitch } from '@src/components/shared/composables/useDisplaySwitch';
import NavigationItems from '@src/components/layout/NavigationItems.vue';

interface Props {
	assetsPath: string;
	pageIdentifier: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  props: {
    assetsPath: {},
    pageIdentifier: {}
  },
  setup(__props: any) {




const campaignParams = inject<string>( QUERY_STRING_INJECTION_KEY, '' );
const showMobileNavbar = ref<boolean>( false );
const headerMenu = [
	{ ids: [ 'donation-form', 'donation-confirmation' ], localeId: 'donate', url: `/?${ campaignParams }` },
	{
		ids: [ 'membership-application', 'membership-application-confirmation' ],
		localeId: 'membership_application',
		url: `/apply-for-membership?${ campaignParams }`,
	},
	{ ids: [ 'use-of-funds' ], localeId: 'use_of_resources', url: `/use-of-funds?${ campaignParams }` },
	{ ids: [ 'faq-page' ], localeId: 'faq', url: `/faq?${ campaignParams }` },
];
const onLargeScreen = useDisplaySwitch( 769 );

const handleMenuItemBlur = () => {
	if ( !showMobileNavbar.value ) {
		return;
	}
	// Use setTimeout to wait for the next tick because the next element won't be focused until then
	setTimeout( () => {
		const classes = document.activeElement.classList;
		if ( !classes.contains( 'navigation-burger' ) && !classes.contains( 'navigation-item' ) ) {
			showMobileNavbar.value = false;
		}
	} );
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "navigation-left",
      href: `/?${ _unref(campaignParams) }`
    }, [
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "is-sr-only" }, "Wikimedia Deutschland e V.", -1)),
      _createVNode(Logo)
    ], 8, _hoisted_2),
    (_unref(onLargeScreen))
      ? (_openBlock(), _createBlock(NavigationItems, {
          key: 0,
          "show-mobile-navbar": showMobileNavbar.value,
          "page-identifier": _ctx.pageIdentifier,
          "header-menu": headerMenu,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showMobileNavbar.value = !showMobileNavbar.value)),
          onBlur: handleMenuItemBlur,
          onEsc: _cache[1] || (_cache[1] = ($event: any) => (showMobileNavbar.value = false))
        }, null, 8, ["show-mobile-navbar", "page-identifier"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(LocaleSelector, { "assets-path": _ctx.assetsPath }, null, 8, ["assets-path"]),
      _createVNode(NavigationBurger, {
        active: showMobileNavbar.value,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (showMobileNavbar.value = !showMobileNavbar.value)),
        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (showMobileNavbar.value = false), ["esc"])),
        onBlur: handleMenuItemBlur
      }, null, 8, ["active"])
    ]),
    (!_unref(onLargeScreen))
      ? (_openBlock(), _createBlock(NavigationItems, {
          key: 1,
          "show-mobile-navbar": showMobileNavbar.value,
          "page-identifier": _ctx.pageIdentifier,
          "header-menu": headerMenu,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (showMobileNavbar.value = !showMobileNavbar.value)),
          onBlur: handleMenuItemBlur,
          onEsc: _cache[5] || (_cache[5] = ($event: any) => (showMobileNavbar.value = false))
        }, null, 8, ["show-mobile-navbar", "page-identifier"]))
      : _createCommentVNode("", true)
  ]))
}
}

})