import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ErrorSummary from '@src/components/shared/validation_summary/ErrorSummary.vue';
import { useStore } from 'vuex';
import { AddressTypeModel } from '@src/view_models/AddressTypeModel';

interface Props {
	showErrorSummary: boolean;
	addressType: AddressTypeModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorSummary',
  props: {
    showErrorSummary: { type: Boolean },
    addressType: {}
  },
  setup(__props: any) {



const store = useStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.addressType === _unref(AddressTypeModel).PERSON || _ctx.addressType === _unref(AddressTypeModel).UNSET)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 0,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.addressType,
				message: _ctx.$t( 'donation_form_section_address_error' ),
				focusElement: 'addressType-0',
				scrollElement: 'address-type-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.salutation,
				message: _ctx.$t( 'donation_form_salutation_error' ),
				focusElement: 'person-salutation-0',
				scrollElement: 'person-salutation-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.firstName,
				message: _ctx.$t( 'donation_form_firstname_error' ),
				focusElement: 'person-first-name',
				scrollElement: 'person-first-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.lastName,
				message: _ctx.$t( 'donation_form_lastname_error' ),
				focusElement: 'person-last-name',
				scrollElement: 'person-last-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.country,
				message: _ctx.$t( 'donation_form_country_error' ),
				focusElement: 'person-country',
				scrollElement: 'person-country-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.postcode,
				message: _ctx.$t( 'donation_form_zip_error' ),
				focusElement: 'person-post-code',
				scrollElement: 'person-post-code-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.city,
				message: _ctx.$t( 'donation_form_city_error' ),
				focusElement: 'person-city',
				scrollElement: 'person-city-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.street,
				message: _ctx.$t( 'donation_form_street_error' ),
				focusElement: 'person-street',
				scrollElement: 'person-street-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'person-email',
				scrollElement: 'person-email-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true),
    (_ctx.addressType === _unref(AddressTypeModel).COMPANY)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 1,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.companyName,
				message: _ctx.$t( 'donation_form_companyname_error' ),
				focusElement: 'company-company-name',
				scrollElement: 'company-company-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.country,
				message: _ctx.$t( 'donation_form_country_error' ),
				focusElement: 'company-country',
				scrollElement: 'company-country-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.postcode,
				message: _ctx.$t( 'donation_form_zip_error' ),
				focusElement: 'company-post-code',
				scrollElement: 'company-post-code-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.city,
				message: _ctx.$t( 'donation_form_city_error' ),
				focusElement: 'company-city',
				scrollElement: 'company-city-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.street,
				message: _ctx.$t( 'donation_form_street_error' ),
				focusElement: 'company-street',
				scrollElement: 'company-street-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'company-email',
				scrollElement: 'company-email-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true),
    (_ctx.addressType === _unref(AddressTypeModel).EMAIL)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 2,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.salutation,
				message: _ctx.$t( 'donation_form_salutation_error' ),
				focusElement: 'email-salutation-0',
				scrollElement: 'email-salutation-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.firstName,
				message: _ctx.$t( 'donation_form_firstname_error' ),
				focusElement: 'email-first-name',
				scrollElement: 'email-first-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.lastName,
				message: _ctx.$t( 'donation_form_lastname_error' ),
				focusElement: 'email-last-name',
				scrollElement: 'email-last-name-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.email,
				message: _ctx.$t( 'donation_form_email_error' ),
				focusElement: 'email-email',
				scrollElement: 'email-email-scroll-target'
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true),
    (_ctx.addressType === _unref(AddressTypeModel).ANON)
      ? (_openBlock(), _createBlock(ErrorSummary, {
          key: 3,
          "is-visible": _ctx.showErrorSummary,
          items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
		]
        }, null, 8, ["is-visible", "items"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})