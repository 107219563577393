import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "address-section" }

import { AddressFormData, AddressValidity } from '@src/view_models/Address';
import TextField from '@src/components/shared/form_fields/TextField.vue';
import ValueEqualsPlaceholderWarning from '@src/components/shared/ValueEqualsPlaceholderWarning.vue';
import CityAutocompleteField from '@src/components/shared/form_fields/CityAutocompleteField.vue';
import CountryAutocompleteField from '@src/components/shared/form_fields/CountryAutocompleteField.vue';
import { Country } from '@src/view_models/Country';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import StreetAutocompleteField from '@src/components/shared/form_fields/StreetAutocompleteField.vue';

interface Props {
	formData: AddressFormData;
	showError: AddressValidity;
	countries: Country[];
	postCodeValidation: string;
	countryWasRestored: boolean;
	fieldIdNamespace?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PostalAddressFields',
  props: {
    formData: {},
    showError: {},
    countries: {},
    postCodeValidation: {},
    countryWasRestored: { type: Boolean },
    fieldIdNamespace: {}
  },
  emits: [ 'field-changed' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const fieldIdNamespace = props.fieldIdNamespace ? `${props.fieldIdNamespace}-` : '';

const onCountryFieldChanged = ( country: Country | undefined ) => {
	if ( country ) {
		props.formData.postcode.pattern = country.postCodeValidation;
	} else {
		props.formData.postcode.pattern = props.postCodeValidation;
	}

	emit( 'field-changed', 'country' );

	if ( props.formData.postcode.value !== '' ) {
		emit( 'field-changed', 'postcode' );
	}
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}country-scroll-target`
    }, null, 8, ["target-id"]),
    _createVNode(CountryAutocompleteField, {
      modelValue: _ctx.formData.country.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.country.value) = $event)),
      "input-id": `${_unref(fieldIdNamespace)}country`,
      "scroll-target-id": `${_unref(fieldIdNamespace)}country-scroll-target`,
      countries: _ctx.countries,
      "was-restored": _ctx.countryWasRestored,
      "show-error": _ctx.showError.country,
      "error-message": _ctx.$t('donation_form_country_error'),
      label: _ctx.$t( 'donation_form_country_label' ),
      placeholder: _ctx.$t( 'form_for_example', { example: _ctx.countries[0].countryFullName } ),
      onFieldChanged: onCountryFieldChanged
    }, null, 8, ["modelValue", "input-id", "scroll-target-id", "countries", "was-restored", "show-error", "error-message", "label", "placeholder"]),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}post-code-scroll-target`
    }, null, 8, ["target-id"]),
    _createVNode(TextField, {
      name: "postcode",
      "input-id": `${_unref(fieldIdNamespace)}post-code`,
      modelValue: _ctx.formData.postcode.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.postcode.value) = $event)),
      "show-error": _ctx.showError.postcode,
      "error-message": _ctx.$t('donation_form_zip_error'),
      autocomplete: "postal-code",
      label: _ctx.$t( 'donation_form_zip_label' ),
      placeholder: _ctx.$t( 'form_for_example', { example: _ctx.$t( 'donation_form_zip_placeholder' ) } ),
      onFieldChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('field-changed', 'postcode')))
    }, {
      message: _withCtx(() => [
        _createVNode(ValueEqualsPlaceholderWarning, {
          value: _ctx.formData.postcode.value,
          placeholder: _ctx.$t( 'donation_form_zip_placeholder' ),
          warning: 'donation_form_zip_placeholder_warning'
        }, null, 8, ["value", "placeholder"])
      ]),
      _: 1
    }, 8, ["input-id", "modelValue", "show-error", "error-message", "label", "placeholder"]),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}city-scroll-target`
    }, null, 8, ["target-id"]),
    _createVNode(CityAutocompleteField, {
      modelValue: _ctx.formData.city.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.city.value) = $event)),
      "input-id": `${_unref(fieldIdNamespace)}city`,
      "scroll-target-id": `${_unref(fieldIdNamespace)}city-scroll-target`,
      "show-error": _ctx.showError.city,
      label: _ctx.$t( 'donation_form_city_label' ),
      "error-message": _ctx.$t( 'donation_form_city_error' ),
      postcode: _ctx.formData.postcode.value,
      "example-placeholder": "donation_form_city_placeholder",
      onFieldChanged: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('field-changed', 'city' )))
    }, {
      message: _withCtx(() => [
        _createVNode(ValueEqualsPlaceholderWarning, {
          value: _ctx.formData.city.value,
          placeholder: _ctx.$t( 'donation_form_city_placeholder' ),
          warning: "donation_form_city_placeholder_warning"
        }, null, 8, ["value", "placeholder"])
      ]),
      _: 1
    }, 8, ["modelValue", "input-id", "scroll-target-id", "show-error", "label", "error-message", "postcode"]),
    _createVNode(ScrollTarget, {
      "target-id": `${_unref(fieldIdNamespace)}street-scroll-target`
    }, null, 8, ["target-id"]),
    _createVNode(StreetAutocompleteField, {
      "input-id-street-name": `${_unref(fieldIdNamespace)}street`,
      "input-id-building-number": `${_unref(fieldIdNamespace)}building-number`,
      "scroll-target-id": `${_unref(fieldIdNamespace)}street-scroll-target`,
      modelValue: _ctx.formData.street.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.street.value) = $event)),
      postcode: _ctx.formData.postcode.value,
      "show-error": _ctx.showError.street,
      "error-message": _ctx.$t( 'donation_form_street_error' ),
      onFieldChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('field-changed', 'street' )))
    }, null, 8, ["input-id-street-name", "input-id-building-number", "scroll-target-id", "modelValue", "postcode", "show-error", "error-message"])
  ]))
}
}

})