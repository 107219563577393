import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "error-summary-list" }
const _hoisted_4 = ["href", "data-scroll-element", "onClick", "onKeydown"]

import { ValidationSummaryItem } from '@src/components/shared/validation_summary/ValidationSummaryItem';
import { Validity } from '@src/view_models/Validity';
import { nextTick, ref, watch } from 'vue';

interface Props {
	isVisible: boolean;
	items: ValidationSummaryItem[];
	idNamespace?: string;
	focusOnSubmit?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorSummary',
  props: {
    isVisible: { type: Boolean },
    items: {},
    idNamespace: { default: '' },
    focusOnSubmit: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;
const errorSummary = ref<HTMLElement>( null );

const onClickError = ( focusElementId: string, scrollElementId: string ) => {
	const focusElement = document.getElementById( focusElementId );
	const scrollElement = document.getElementById( scrollElementId );
	const focusOptions = { preventScroll: false };

	if ( scrollElement ) {
		scrollElement.scrollIntoView( { behavior: 'smooth' } );
		focusOptions.preventScroll = true;
	}

	focusElement.focus( focusOptions );
};

watch( () => props.isVisible, async ( newIsVisible: boolean ) => {
	if ( props.focusOnSubmit && newIsVisible ) {
		await nextTick();
		errorSummary.value.focus();
	}
} );


return (_ctx: any,_cache: any) => {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "errorSummary",
        ref: errorSummary,
        class: "error-summary",
        "aria-live": "assertive",
        "aria-atomic": "true",
        "aria-relevant": "all",
        role: "region",
        "aria-labelledby": `${_ctx.idNamespace}error-summary-heading`,
        tabindex: "-1"
      }, [
        _createElementVNode("h2", {
          class: "error-summary-heading",
          id: `${_ctx.idNamespace}error-summary-heading`
        }, _toDisplayString(_ctx.$t( 'error_summary_headline' )), 9, _hoisted_2),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.validity === _unref(Validity).INVALID)
                ? (_openBlock(), _createElementBlock("li", {
                    key: item.focusElement
                  }, [
                    _createElementVNode("a", {
                      href: `#${ item.focusElement }`,
                      "data-scroll-element": item.scrollElement,
                      onClick: _withModifiers(() => onClickError( item.focusElement, item.scrollElement ), ["prevent"]),
                      onKeydown: [
                        _withKeys(() => onClickError( item.focusElement, item.scrollElement ), ["space"]),
                        _withKeys(() => onClickError( item.focusElement, item.scrollElement ), ["enter"])
                      ]
                    }, _toDisplayString(item.message), 41, _hoisted_4)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})