import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form-field-autocomplete-container" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "dropdown-menu" }
const _hoisted_5 = ["onClick", "onKeyup"]
const _hoisted_6 = ["id"]

import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { useCitiesResource } from '@src/components/shared/form_fields/useCitiesResource';
import { CityAutocompleteResource, NullCityAutocompleteResource } from '@src/api/CityAutocompleteResource';
import TextFormInput from '@src/components/shared/form_elements/TextFormInput.vue';
import { updateAutocompleteScrollPosition } from '@src/components/shared/form_fields/updateAutocompleteScrollPosition';
import { useAriaDescribedby } from '@src/components/shared/form_fields/useAriaDescribedby';
import { autoscrollMaxWidth, useAutocompleteScrollIntoViewOnFocus } from '@src/components/shared/form_fields/useAutocompleteScrollIntoViewOnFocus';

enum InteractionState {
	Typing,
	Selecting,
}

interface Props {
	modelValue: string;
	inputId: string;
	scrollTargetId: string;
	label: String;
	examplePlaceholder: string;
	showError: boolean;
	errorMessage: String;
	postcode: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CityAutocompleteField',
  props: {
    modelValue: {},
    inputId: {},
    scrollTargetId: {},
    label: {},
    examplePlaceholder: {},
    showError: { type: Boolean },
    errorMessage: {},
    postcode: {}
  },
  emits: [ 'field-changed', 'update:modelValue' ],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const city = ref<string>( props.modelValue );
const autocompleteIsActive = ref<Boolean>( false );
const { cities, fetchCitiesForPostcode } = useCitiesResource( inject<CityAutocompleteResource>( 'cityAutocompleteResource', NullCityAutocompleteResource ) );
const activeCity = ref<string>();
const interactionState = ref<InteractionState>( InteractionState.Typing );
const scrollElement = ref<HTMLElement>();
const ariaDescribedby = useAriaDescribedby(
	computed<string>( () => activeCity.value ? `${props.inputId}-selected` : '' ),
	`${props.inputId}-error`,
	computed<boolean>( () => props.showError )
);
const scrollIntoView = useAutocompleteScrollIntoViewOnFocus( props.scrollTargetId, autoscrollMaxWidth );

const placeholder = computed( () => {
	if ( cities.value.length > 0 ) {
		return 'form_autocomplete_prompt';
	}
	return 'form_for_example';
} );

const onFocus = ( event: Event ) => {
	autocompleteIsActive.value = true;
	scrollIntoView();
	( event.target as HTMLInputElement ).select();
};

const onKeydown = ( event: KeyboardEvent ) => {
	if ( [ 'ArrowUp', 'ArrowDown', 'Tab', 'Enter' ].includes( event.key ) ) {
		return true;
	}

	interactionState.value = InteractionState.Typing;
	activeCity.value = undefined;
};

const onKeyArrows = async ( direction: 'up' | 'down' ) => {
	interactionState.value = InteractionState.Selecting;

	if ( activeCity.value === undefined ) {
		activeCity.value = cities.value[ 0 ];
		return;
	}

	let index = cities.value.findIndex( x => x === activeCity.value );

	if ( direction === 'up' && index > 0 ) {
		index--;
	}

	if ( direction === 'down' && index + 1 < cities.value.length ) {
		index++;
	}

	activeCity.value = cities.value[ index ];

	await nextTick();
	updateAutocompleteScrollPosition( scrollElement );
};

const onKeySubmit = () => {
	if ( interactionState.value === InteractionState.Typing ) {
		return;
	}

	city.value = activeCity.value;
};

let itemWasJustSelectedFromList = false;

const onBlur = () => {
	setTimeout( () => {
		autocompleteIsActive.value = false;
		if ( !itemWasJustSelectedFromList ) {
			emit( 'field-changed' );
		}
		itemWasJustSelectedFromList = false;
	}, 200 );
};

const onSelectItem = async ( newCity: string ) => {
	itemWasJustSelectedFromList = true;
	city.value = newCity;
	await nextTick();
	emit( 'field-changed' );
};

onMounted( () => {
	fetchCitiesForPostcode( props.postcode as string );
} );

watch( (): string => props.postcode as string, ( value: string ) => {
	fetchCitiesForPostcode( value );
} );

watch( city, ( newCity: string ) => {
	emit( 'update:modelValue', newCity );
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-field form-field-autocomplete", { 'is-invalid': _ctx.showError }])
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: "form-field-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TextFormInput, {
        modelValue: city.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((city).value = $event)),
        "input-type": "text",
        name: "city",
        placeholder: _ctx.$t( placeholder.value, { example: _ctx.$t( _ctx.examplePlaceholder ) } ),
        "has-error": _ctx.showError,
        "has-message": false,
        "input-id": _ctx.inputId,
        onFocus: onFocus,
        onBlur: onBlur,
        onKeydown: [
          onKeydown,
          _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (onKeyArrows( 'up' )), ["prevent"]), ["up"])),
          _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (onKeyArrows( 'down' )), ["prevent"]), ["down"])),
          _withKeys(onKeySubmit, ["tab"]),
          _withKeys(onKeySubmit, ["enter"])
        ],
        "aria-describedby": _unref(ariaDescribedby),
        "aria-autocomplete": "list"
      }, null, 8, ["modelValue", "placeholder", "has-error", "input-id", "aria-describedby"]),
      _createElementVNode("span", {
        class: "is-sr-only",
        id: `${_ctx.inputId}-selected`,
        "aria-live": "assertive"
      }, _toDisplayString(activeCity.value), 9, _hoisted_3),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "dropdown-content",
              ref_key: "scrollElement",
              ref: scrollElement,
              tabindex: "-1"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cities), (city) => {
                return (_openBlock(), _createElementBlock("a", {
                  class: _normalizeClass(["dropdown-item", { 'is-active-item': city === activeCity.value }]),
                  key: city,
                  role: "button",
                  tabindex: "-1",
                  onClick: _withModifiers(($event: any) => (onSelectItem( city )), ["stop"]),
                  onKeyup: _withKeys(($event: any) => (onSelectItem( city )), ["enter","space"])
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.postcode), 1),
                  _createTextVNode(" " + _toDisplayString(city), 1)
                ], 42, _hoisted_5))
              }), 128))
            ], 512)
          ], 512), [
            [_vShow, autocompleteIsActive.value && _unref(cities).length > 0]
          ])
        ]),
        _: 1
      })
    ]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "help is-danger",
          id: `${_ctx.inputId}-error`
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_6))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "message")
  ], 2))
}
}

})