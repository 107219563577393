import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "payment-summary" }
const _hoisted_2 = { class: "payment-summary-text" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "payment-summary-link" }

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

interface Props {
	amount: number;
	interval: string;
	paymentType?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentSummary',
  props: {
    amount: {},
    interval: {},
    paymentType: { default: '' }
  },
  setup(__props: any) {

const { t, n } = useI18n();
const props = __props;

const summary = computed( () => {
	const interval = t( 'donation_form_payment_interval_' + props.interval );
	const formattedAmount = n( props.amount, { key: 'currency', currencyDisplay: 'name' } );
	const paymentType = t( props.paymentType );
	return t( 'donation_form_payment_summary', { interval, formattedAmount, paymentType } );
} );

const summaryWithoutPaymentType = computed( () => {
	const interval = t( 'donation_form_payment_interval_' + props.interval );
	const formattedAmount = n( props.amount, { key: 'currency', currencyDisplay: 'name' } );
	return t( 'donation_form_payment_summary_payment_type_missing', { interval, formattedAmount } );
} );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        innerHTML: props.paymentType ? summary.value : summaryWithoutPaymentType.value
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit( 'show-payment-form' )), ["prevent"]))
      }, _toDisplayString(_ctx.$t('donation_form_section_back')), 1)
    ])
  ]))
}
}

})