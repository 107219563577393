import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-summary-buttons" }

interface Props {
	showBorder?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSummary',
  props: {
    showBorder: { type: Boolean, default: true }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-summary", { 'form-summary-border': _ctx.showBorder }])
  }, [
    _renderSlot(_ctx.$slots, "summary-content"),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "summary-buttons")
    ]),
    _renderSlot(_ctx.$slots, "summary-notice")
  ], 2))
}
}

})