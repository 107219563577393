import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ErrorSummary from '@src/components/shared/validation_summary/ErrorSummary.vue';
import { useStore } from 'vuex';

interface Props {
	showErrorSummary: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorSummary',
  props: {
    showErrorSummary: { type: Boolean }
  },
  setup(__props: any) {

const store = useStore();




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ErrorSummary, {
    "is-visible": _ctx.showErrorSummary,
    items: [
			{
				validity: _unref(store).state.payment.validity.amount,
				message: _ctx.$t( 'error_summary_amount' ),
				focusElement: 'amount-500',
				scrollElement: 'payment-form-amount-scroll-target',
			},
			{
				validity: _unref(store).state.payment.validity.type,
				message: _ctx.$t( 'error_summary_payment_type' ),
				focusElement: 'paymentType-0',
				scrollElement: 'payment-form-type-scroll-target',
			},
			{
				validity: _unref(store).state.bankdata.validity.iban,
				message: _ctx.$t( 'donation_form_payment_iban_error' ),
				focusElement: 'iban',
				scrollElement: 'iban-scroll-target'
			},
			{
				validity: _unref(store).state.address.validity.addressType,
				message: _ctx.$t( 'error_summary_address_type_opt_out' ),
				focusElement: 'addressOptOut-1',
				scrollElement: 'address-opt-out-scroll-target',
			},
		]
  }, null, 8, ["is-visible", "items"]))
}
}

})