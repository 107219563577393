import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["name", "id", "autocomplete", "autofocus", "placeholder", "disabled", "required", "aria-invalid", "aria-describedby", "aria-autocomplete"]
const _hoisted_2 = ["name", "id", "autocomplete", "autofocus", "placeholder", "disabled", "required", "aria-invalid", "aria-describedby"]
const _hoisted_3 = {
  key: 2,
  class: "icon is-right has-text-danger"
}
const _hoisted_4 = {
  key: 3,
  class: "icon is-right has-text-warning"
}

import { useInputModel } from '@src/components/shared/form_elements/useInputModel';

interface Props {
	inputType: 'text' | 'textarea';
	name: string;
	modelValue: string | number;
	autocomplete?: string;
	autofocus?: boolean;
	inputId: string;
	placeholder: string;
	hasMessage: boolean;
	hasError?: boolean;
	disabled?: boolean;
	required?: boolean;
	ariaDescribedby?: string;
	ariaAutocomplete?: 'none' | 'inline' | 'list' | 'both';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextFormInput',
  props: {
    inputType: {},
    name: {},
    modelValue: {},
    autocomplete: { default: 'on' },
    autofocus: { type: Boolean },
    inputId: {},
    placeholder: {},
    hasMessage: { type: Boolean },
    hasError: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    ariaDescribedby: {},
    ariaAutocomplete: {}
  },
  emits: [ 'update:modelValue', 'focus', 'blur' ],
  setup(__props: any, { emit: __emit }) {


const props = __props;
const emit = __emit;

const inputModel = useInputModel<string | number>( () => props.modelValue, props.modelValue, emit );

const onFocus = ( event: Event ): void => emit( 'focus', event );
const onBlur = ( event: Event ): void => emit( 'blur', event );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control text-form-input", { 'has-icons-right': _ctx.hasError || _ctx.hasMessage, 'is-disabled': _ctx.disabled }])
  }, [
    (_ctx.inputType === 'text')
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          name: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputModel) ? (inputModel).value = $event : null)),
          class: _normalizeClass(["input", { 'is-danger': _ctx.hasError }]),
          id: _ctx.inputId,
          type: "text",
          autocomplete: _ctx.autocomplete,
          autofocus: _ctx.autofocus,
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          required: _ctx.required,
          "aria-invalid": _ctx.hasError,
          "aria-describedby": _ctx.ariaDescribedby,
          "aria-autocomplete": _ctx.ariaAutocomplete,
          onBlur: onBlur,
          onFocus: onFocus
        }, null, 42, _hoisted_1)), [
          [_vModelText, _unref(inputModel)]
        ])
      : _createCommentVNode("", true),
    (_ctx.inputType === 'textarea')
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          name: _ctx.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(inputModel) ? (inputModel).value = $event : null)),
          class: _normalizeClass(["textarea", { 'is-danger': _ctx.hasError }]),
          id: _ctx.inputId,
          autocomplete: _ctx.autocomplete,
          autofocus: _ctx.autofocus,
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          required: _ctx.required,
          "aria-invalid": _ctx.hasError,
          "aria-describedby": _ctx.ariaDescribedby,
          onBlur: onBlur,
          onFocus: onFocus
        }, null, 42, _hoisted_2)), [
          [_vModelText, _unref(inputModel)]
        ])
      : _createCommentVNode("", true),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "mdi mdi-alert-circle mdi-24px" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.hasMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "mdi mdi-alert mdi-24px" }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ], 2))
}
}

})