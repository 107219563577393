import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label", "aria-expanded"]

interface Props {
	active: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationBurger',
  props: {
    active: { type: Boolean }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["navigation-burger", { 'active': _ctx.active }]),
    "aria-controls": "main-navigation-items",
    "aria-label": _ctx.$t( _ctx.active ? 'aria_navigation_toggle_label_open' : 'aria_navigation_toggle_label_closed' ),
    "aria-expanded": _ctx.active
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
    _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
    _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
  ]), 10, _hoisted_1))
}
}

})