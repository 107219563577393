import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

import LoadingSpinner from '@src/components/shared/LoadingSpinner.vue';

interface Props {
	buttonType?: 'submit' | 'reset' | 'button';
	isOutlined?: boolean;
	isLoading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormButton',
  props: {
    buttonType: { default: 'button' },
    isOutlined: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([ 'form-button', { 'form-button-outlined': _ctx.isOutlined, 'form-button-loading' : _ctx.isLoading } ]),
    type: _ctx.buttonType
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})