import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "donation-page-form-section-payment",
  class: "donation-page-form-section"
}
const _hoisted_2 = {
  id: "donation-form-heading",
  class: "form-title"
}
const _hoisted_3 = {
  id: "donation-form-subheading",
  class: "form-subtitle"
}
const _hoisted_4 = {
  key: 1,
  class: "show-summary-and-payment-type"
}
const _hoisted_5 = {
  key: 2,
  name: "laika-donation-payment",
  class: "payment-page",
  ref: "paymentForm"
}

import Payment from '@src/components/pages/donation_form/Payment.vue';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';
import { ref } from 'vue';
import PaymentSummary from '@src/components/pages/donation_form/PaymentSummary.vue';
import { useStore } from 'vuex';
import { usePaymentFunctions } from '@src/components/pages/donation_form/usePaymentFunctions';
import { Validity } from '@src/view_models/Validity';

interface Props {
	paymentAmounts: number[];
	paymentIntervals: number[];
	paymentTypes: string[];
}

type formStates = 'showEntireForm' | 'showSummary' | 'showSummaryAndPaymentType';

export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentSection',
  props: {
    paymentAmounts: {},
    paymentIntervals: {},
    paymentTypes: {}
  },
  setup(__props: any) {



const store = useStore();
let initialFormState: formStates;

if ( store.state.payment.validity.type === Validity.VALID && store.state.payment.validity.amount === Validity.VALID ) {
	initialFormState = 'showSummary';
} else if ( store.state.payment.validity.amount === Validity.VALID ) {
	initialFormState = 'showSummaryAndPaymentType';
} else {
	initialFormState = 'showEntireForm';
}

const state = ref<formStates>( initialFormState );

const { paymentSummary } = usePaymentFunctions( store );


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ScrollTarget, { "target-id": "payment-section-top-scroll-target" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t( 'donation_form_heading' )), 1),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t( 'donation_form_payment_subheading' )), 1),
      (state.value === 'showSummary')
        ? (_openBlock(), _createBlock(PaymentSummary, {
            key: 0,
            onShowPaymentForm: _cache[0] || (_cache[0] = ($event: any) => (state.value='showEntireForm')),
            amount: _unref(paymentSummary).amount,
            interval: _unref(paymentSummary).interval,
            "payment-type": _unref(paymentSummary).paymentType
          }, null, 8, ["amount", "interval", "payment-type"]))
        : _createCommentVNode("", true),
      (state.value === 'showSummaryAndPaymentType')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(PaymentSummary, {
              onShowPaymentForm: _cache[1] || (_cache[1] = ($event: any) => (state.value='showEntireForm')),
              amount: _unref(paymentSummary).amount,
              interval: _unref(paymentSummary).interval,
              "payment-type": _unref(paymentSummary).paymentType
            }, null, 8, ["amount", "interval", "payment-type"]),
            _createVNode(Payment, {
              "payment-amounts": _ctx.paymentAmounts,
              "payment-intervals": _ctx.paymentIntervals,
              "payment-types": _ctx.paymentTypes,
              "display-sections": [ 'paymentType' ]
            }, null, 8, ["payment-amounts", "payment-intervals", "payment-types"])
          ]))
        : _createCommentVNode("", true),
      (state.value === 'showEntireForm')
        ? (_openBlock(), _createElementBlock("form", _hoisted_5, [
            _createVNode(Payment, {
              "payment-amounts": _ctx.paymentAmounts,
              "payment-intervals": _ctx.paymentIntervals,
              "payment-types": _ctx.paymentTypes
            }, null, 8, ["payment-amounts", "payment-intervals", "payment-types"])
          ], 512))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})